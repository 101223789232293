import React from "react";
import { isEmpty } from "lodash";
import { Input, Label } from "reactstrap";
import { RenderIf } from "../../../utils/common";

const FormInputField = ({
  inputType = "text",
  label,
  extendedLabel,
  placeholder,
  value,
  setFieldValue,
  fieldValue,
  prefix,
  postfix,
  postfixColour,
  onClickPostFix,
  isAffixDynamic = true,
  ...dynamicProps
}) => {
  const isValueEmpty = !isEmpty(value);
  const showAffix = isAffixDynamic ? isValueEmpty : true;

  return (
    <div className="font-size-13 position-relative">
      <RenderIf isTrue={label}>
        <Label>
          {label}
          <RenderIf isTrue={extendedLabel}>
            <span style={{ color: "#6D6E7F" }}> {extendedLabel} </span>
          </RenderIf>
        </Label>
      </RenderIf>
      <RenderIf isTrue={prefix && showAffix}>
        <span
          style={{
            position: "absolute",
            left: "12px",
            bottom: "15%",
          }}
        >
          {prefix}
        </span>
      </RenderIf>
      <Input
        type={inputType}
        className={"form-control"}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setFieldValue(fieldValue, e.target.value)}
        style={
          { paddingLeft: prefix && showAffix ? "22px" : "" }
        }
        {...dynamicProps}
      />
      <RenderIf isTrue={postfix && showAffix}>
        <span
          className="cursor-pointer"
          style={{
            position: "absolute",
            right: "10px",
            bottom: "15%",
            color: postfixColour,
          }}
          onClick={onClickPostFix}
        >
          {postfix}
        </span>
      </RenderIf>
    </div>
  );
};

export default FormInputField;
