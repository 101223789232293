import { REPRICER_LISTING_TYPES, REPRICER_LISTINGS_PAGES } from "../../../constants";

// actionTypes.js
export const actionTypes = {
  SET_STORE_FRONT: "SET_STORE_FRONT",
  SET_FILTERS: "SET_FILTERS",
  SET_SELECTED: "SET_SELECTED",
  SET_LISTINGS_PAGE: "SET_LISTINGS_PAGE",
  SET_DIMENSION_MODAL_OPEN: "SET_DIMENSION_MODAL_OPEN",
  SET_ITEM: "SET_ITEM",
  SET_SELECTED_MAP_PRICE: "SET_SELECTED_MAP_PRICE",
  SET_SELECTED_LISTED_PRICE: "SET_SELECTED_LISTED_PRICE",
  SET_SELECTED_TAG: "SET_SELECTED_TAG",
  SET_SEARCH_TYPE: "SET_SEARCH_TYPE",
  SET_MIN_MAX_PRICE: "SET_MIN_MAX_PRICE",
  SET_CHECKBOX: "SET_CHECKBOX",
  SET_SELECT_ALL: "SET_SELECT_ALL",
  SET_SEARCH: "SET_SEARCH",
  SET_LOADER_KEY: "SET_LOADER_KEY",
  SET_SHOW_PROFIT_MARGIN: "SET_SHOW_PROFIT_MARGIN",
  SET_ARE_FILTERS_OPEN: "SET_ARE_FILTERS_OPEN",
  SET_REPRICER_LISTINGS_MODAL_PROPS: "SET_REPRICER_LISTINGS_MODAL_PROPS",
  SET_ASSIGN_STRATEGY_MODAL_PROPS: "SET_ASSIGN_STRATEGY_MODAL_PROPS",
  SET_IS_ADD_LISTING_OPEN: "SET_IS_ADD_LISTING_OPEN",
  SET_IS_ADD_BULK_LISTING_OPEN: "SET_IS_ADD_BULK_LISTING_OPEN",
};

export const actions = {
  setStoreFront: (payload) => ({ type: actionTypes.SET_STORE_FRONT, payload }),
  setFilters: (payload) => ({ type: actionTypes.SET_FILTERS, payload }),
  setSelected: (payload) => ({ type: actionTypes.SET_SELECTED, payload }),
  setListingsPage: (payload) => ({ type: actionTypes.SET_LISTINGS_PAGE, payload }),
  setDimensionModalOpen: (payload) => ({ type: actionTypes.SET_DIMENSION_MODAL_OPEN, payload }),
  setItem: (payload) => ({ type: actionTypes.SET_ITEM, payload }),
  setSelectedMapPrice: (payload) => ({ type: actionTypes.SET_SELECTED_MAP_PRICE, payload }),
  setSelectedListedPrice: (payload) => ({ type: actionTypes.SET_SELECTED_LISTED_PRICE, payload }),
  setSelectedTag: (payload) => ({ type: actionTypes.SET_SELECTED_TAG, payload }),
  setSearchType: (payload) => ({ type: actionTypes.SET_SEARCH_TYPE, payload }),
  setMinMaxPrice: (payload) => ({ type: actionTypes.SET_MIN_MAX_PRICE, payload }),
  setCheckBox: (payload) => ({ type: actionTypes.SET_CHECKBOX, payload }),
  setSelectAll: (payload) => ({ type: actionTypes.SET_SELECT_ALL, payload }),
  setSearch: (payload) => ({ type: actionTypes.SET_SEARCH, payload }),
  setLoaderKey: (payload) => ({ type: actionTypes.SET_LOADER_KEY, payload }),
  setShowProfitMargin: (payload) => ({ type: actionTypes.SET_SHOW_PROFIT_MARGIN, payload }),
  setAreFiltersOpen: (payload) => ({ type: actionTypes.SET_ARE_FILTERS_OPEN, payload }),
  setRepricerListingsModalProps: (payload) => ({ type: actionTypes.SET_REPRICER_LISTINGS_MODAL_PROPS, payload }),
  setAssignStrategyModalProps: (payload) => ({ type: actionTypes.SET_ASSIGN_STRATEGY_MODAL_PROPS, payload }),
  setIsAddListingOpen: (payload) => ({ type: actionTypes.SET_IS_ADD_LISTING_OPEN, payload }),
  setIsAddBulkListingOpen: (payload) => ({ type: actionTypes.SET_IS_ADD_BULK_LISTING_OPEN, payload }),
};

export const getInitialState = ({ queryParams }) => ({
  storeFront: { label: "", value: "" },
  filters: {
    page: Number(queryParams?.page) || 1,
    per_page: Number(queryParams?.per_page) || 50,
  },
  selected: "",
  listingsPage: {
    label: REPRICER_LISTINGS_PAGES.find(page => page.value === REPRICER_LISTING_TYPES.live).label,
    value: REPRICER_LISTING_TYPES.live,
  },
  dimensionModalOpen: false,
  item: {},
  selectedMapPrice: "",
  selectedListedPrice: "",
  selectedTag: null,
  searchType: { label: "All", value: "" },
  minMaxPrice: { min_price: "0", max_price: "0" },
  checkBox: {},
  selectAll: false,
  search: "",
  loaderKey: {},
  showProfitMargin: true,
  areFiltersOpen: false,
  repricerListingsModalProps: {},
  assignStrategyModalProps: {},
  isAddListingOpen: false,
  isAddBulkListingOpen: false,
});

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_STORE_FRONT:
      return { ...state, storeFront: action.payload };
    case actionTypes.SET_FILTERS:
      return { ...state, filters: action.payload };
    case actionTypes.SET_SELECTED:
      return { ...state, selected: action.payload };
    case actionTypes.SET_LISTINGS_PAGE:
      return { ...state, listingsPage: action.payload };
    case actionTypes.SET_DIMENSION_MODAL_OPEN:
      return { ...state, dimensionModalOpen: action.payload };
    case actionTypes.SET_ITEM:
      return { ...state, item: action.payload };
    case actionTypes.SET_SELECTED_MAP_PRICE:
      return { ...state, selectedMapPrice: action.payload };
    case actionTypes.SET_SELECTED_LISTED_PRICE:
      return { ...state, selectedListedPrice: action.payload };
    case actionTypes.SET_SELECTED_TAG:
      return { ...state, selectedTag: action.payload };
    case actionTypes.SET_SEARCH_TYPE:
      return { ...state, searchType: action.payload };
    case actionTypes.SET_MIN_MAX_PRICE:
      return { ...state, minMaxPrice: action.payload };
    case actionTypes.SET_CHECKBOX:
      return { ...state, checkBox: action.payload };
    case actionTypes.SET_SELECT_ALL:
      return { ...state, selectAll: action.payload };
    case actionTypes.SET_SEARCH:
      return { ...state, search: action.payload };
    case actionTypes.SET_LOADER_KEY:
      return { ...state, loaderKey: action.payload };
    case actionTypes.SET_SHOW_PROFIT_MARGIN:
      return { ...state, showProfitMargin: action.payload };
    case actionTypes.SET_ARE_FILTERS_OPEN:
      return { ...state, areFiltersOpen: action.payload };
    case actionTypes.SET_REPRICER_LISTINGS_MODAL_PROPS:
      return { ...state, repricerListingsModalProps: action.payload };
    case actionTypes.SET_ASSIGN_STRATEGY_MODAL_PROPS:
      return { ...state, assignStrategyModalProps: action.payload };
    case actionTypes.SET_IS_ADD_LISTING_OPEN:
      return { ...state, isAddListingOpen: action.payload };
    case actionTypes.SET_IS_ADD_BULK_LISTING_OPEN:
      return { ...state, isAddBulkListingOpen: action.payload };
    default:
      return state;
  }
};
