import moment from "moment";
import * as $ from "lodash";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { DATE_FILTERS } from "./common";
import FilterChipsBar from "./FilterChipsBar";
import { RenderIf } from "../../../utils/common";
import ReactTable from "../../../components/Common/Table";
import CheckBox from "../../../components/Common/CheckBox";
import { fetchListings } from "../../../store/repricer/actions";
import { actions as localRepricerActions } from "../LocalStore/RepricerListingsStore";
import { getAccount, isStoreWalmart, isVisible, setLocalStorageItems } from "../common";

function LiveListingTable ({ cols, state, handleDispatch }) {
  const sortCount = useRef(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedMinPrice, setSelectedMinPrice] = useState("");
  const [selectedMaxPrice, setSelectedMaxPrice] = useState("");
  const [minPricePopoverIsOpen, setMinPricePopoverIsOpen] = useState({});
  const [maxPricePopoverIsOpen, setMaxPricePopoverIsOpen] = useState({});
  const { listings, listingsResults, loading, loader, tags, strategies } = useSelector((state) => state.Repricer);
  const selectedListingsCount = $.size($.keys(state?.checkBox));
  const filteredListingsCount = state?.selectAll ? listings?.count : selectedListingsCount;
  const checkBoxState = state?.selectAll || $.isEqual(selectedListingsCount, $.size(listings?.results));
  const isAnyListingSelected = state?.selectAll || selectedListingsCount;
  const isWalmart = isStoreWalmart(state?.storeFront?.marketplace);
  const queryParams = queryString.parse(document.location.search);
  const mappedTags = $.keyBy(tags?.results, "id");
  const mappedStrategies = $.keyBy(strategies, "id");
  const fieldsMapping = {
    Stock: "stock",
    COGS: "cost",
    "Min Price": "minimum_price",
    "Max Price": "maximum_price",
  };

  const toggleMapping = {
    "Margin/ROI": "",
  };

  const getSortFilter = (title, dir) =>
    handleDispatch(
      localRepricerActions.setFilters({
        ...state?.filters,
        sort_by: fieldsMapping[title],
        sort_dir: !dir || dir === "desc" ? "asc" : "desc",
        ordering: !dir || dir === "desc" ? fieldsMapping[title] : "-" + fieldsMapping[title],
      }),
    );

  const SortArrows = ({ title }) => {
    const sortOpts = [
      ["asc", "up"],
      ["desc", "down"],
    ];
    const isSameCol = fieldsMapping[title] === state?.filters.sort_by;
    return (
      <span
        onClick={() => {
          if (fieldsMapping[title] === state?.filters.sort_by) sortCount.current++;
          else sortCount.current = 1;
          if (sortCount.current === 3)
            handleDispatch(
              localRepricerActions.setFilters({ ...state?.filters, sort_by: null, sort_dir: null, ordering: null }),
            );
          else getSortFilter(title, isSameCol && sortCount.current < 3 ? state?.filters.sort_dir : null);
        }}
      >
        {sortOpts
          .filter(([dir]) => (isSameCol ? dir === state?.filters.sort_dir : true))
          .map(([, arrow], index) => (
            <i
              key={"_sort_opts_" + index}
              id={arrow + "-filter"}
              style={{ fontSize: "10px" }}
              className={`dripicons-arrow-thin-${arrow} cursor-pointer`}
            ></i>
          ))}
      </span>
    );
  };

  const redirectToBasePath = () => {
    if (state?.filters.page !== 1 && state?.filters.page_number !== 1) return;

    const { pathname, search } = history.location;
    if (search === "") return;

    history.push(pathname);
  };

  useEffect(() => {
    if (loading || !state?.storeFront.value) return;

    const limit = state?.filters?.per_page;
    const offset = (state?.filters?.page - 1) * state?.filters?.per_page;

    const fetchListingParams = $.merge(
      getAccount(state?.storeFront),
      $.omit(state?.filters, DATE_FILTERS),
      limit && { limit },
      offset && { offset },
    );

    DATE_FILTERS.forEach((filterKey) => {
      const filterValue = parseInt($.get(state?.filters, filterKey));
      if (filterValue) {
        const dateValue = moment().subtract(filterValue, "minutes").format("YYYY-MM-DDTHH:mm:ss");
        const suffix = filterValue === 9999 ? "__lte" : "__gte";
        $.set(fetchListingParams, filterKey + suffix, dateValue);
      }
    });

    dispatch(fetchListings(fetchListingParams));
    redirectToBasePath();

    const { account_id: accountId } = queryParams;
    if (!accountId) {
      setLocalStorageItems("listingFilters", JSON.stringify(state?.filters));
    }
  }, [state?.filters, state?.storeFront.value]);

  return (
    <React.Fragment>
      <RenderIf isTrue={isAnyListingSelected}>
        <div className="mb-3 blockquote-warning">
          <h6 className="d-flex align-items-center">
            <span className="text-warning">
              {`${filteredListingsCount} listing${filteredListingsCount > 1 ? "s" : ""}`}&nbsp;
            </span>{" "}
            selected on current page
          </h6>
          <h6 className="d-flex align-items-center">
            <CheckBox
              state={state?.selectAll}
              setState={(e) => {
                handleDispatch(localRepricerActions.setCheckBox({}));
                handleDispatch(localRepricerActions.setSelectAll(!state?.selectAll));
              }}
            />
            <span>
              Select All <span className="text-warning">{listings?.count} filtered listings</span> (Only few actions
              will work)
            </span>
          </h6>
        </div>
      </RenderIf>
      <FilterChipsBar
        filters={state?.filters}
        setFilters={(filters) => handleDispatch(localRepricerActions.setFilters(filters))}
        initialOptions={{ strategies, tags, isWalmart, showProfitMargin: state?.showProfitMargin }}
      />
      <ReactTable
        className="table-select"
        tableHeader={
          <>
            <th>
              <CheckBox
                state={isAnyListingSelected && checkBoxState}
                setState={(e) => {
                  handleDispatch(localRepricerActions.setSelectAll(false));
                  checkBoxState
                    ? handleDispatch(localRepricerActions.setCheckBox({}))
                    : handleDispatch(
                      localRepricerActions.setCheckBox(
                        $.reduce(listings?.results, (acc, cur) => $.merge(acc, { [cur.id]: true }), {}),
                      ),
                    );
                }}
              />
            </th>
            {Object.values(cols)
              .filter((x) => isVisible(x, true))
              .map((header, index) => (
                <th key={`table-header ${index}`}>
                  {header.renderHeader ? (
                    <header.renderHeader showProfitMargin={state?.showProfitMargin} />
                  ) : (
                    header.title
                  )}
                  <RenderIf isTrue={$.keys(fieldsMapping).includes(header.title)}>
                    <div className="d-inline ml-1">
                      <SortArrows title={header.title} />
                    </div>
                  </RenderIf>
                  <RenderIf isTrue={$.keys(toggleMapping).includes(header.title)}>
                    <div className="d-inline ml-1">
                      <i
                        className="text-primary cursor-pointer bx bx-xs bx-transfer-alt"
                        onClick={() => {
                          const { account_id: accountId } = queryParams;
                          handleDispatch(localRepricerActions.setShowProfitMargin(!state?.showProfitMargin));
                          if (!accountId) {
                            setLocalStorageItems("showProfitMargin", !state?.showProfitMargin);
                          }
                        }}
                      ></i>
                    </div>
                  </RenderIf>
                </th>
              ))}
          </>
        }
        style={{ width: "100%", overflow: "auto" }}
        tableBody={
          <RenderIf
            isTrue={!$.isEmpty(listingsResults)}
            fallback={
              <RenderIf isTrue={!loading}>
                <tr>
                  <td colSpan="100%">
                    <h2 className="text-center">No Records Found</h2>
                  </td>
                </tr>
              </RenderIf>
            }
          >
            {$.map(listings?.results, (data, key) => (
              <tr key={`table-row ${key}`}>
                <th>
                  <CheckBox
                    state={state?.selectAll || state?.checkBox[data.id] || false}
                    setState={(e) => {
                      handleDispatch(localRepricerActions.setSelectAll(false));
                      state?.checkBox[data.id] ? delete state.checkBox[data.id] : (state.checkBox[data.id] = true);
                      handleDispatch(localRepricerActions.setCheckBox({ ...state?.checkBox }));
                    }}
                  />
                </th>
                {$.values(cols)
                  .filter((x) => isVisible(x, true))
                  .map((header, colKey) => (
                    <td key={`table-col ${colKey}`}>
                      <header.Content
                        listings={listings?.results}
                        data={data}
                        showProfitMargin={state?.showProfitMargin}
                        selected={state?.selected}
                        selectedMapPrice={state?.selectedMapPrice}
                        selectedListedPrice={state?.selectedListedPrice}
                        selectedTag={state?.selectedTag}
                        selectedMinPrice={selectedMinPrice}
                        setSelectedMinPrice={setSelectedMinPrice}
                        selectedMaxPrice={selectedMaxPrice}
                        setSelectedMaxPrice={setSelectedMaxPrice}
                        minMaxPrice={state?.minMaxPrice}
                        mappedTags={mappedTags}
                        mappedStrategies={mappedStrategies}
                        loader={loader}
                        strategies={strategies}
                        storeFront={state?.storeFront}
                        loaderKey={state?.loaderKey}
                        minPricePopoverIsOpen={minPricePopoverIsOpen}
                        setMinPricePopoverIsOpen={setMinPricePopoverIsOpen}
                        maxPricePopoverIsOpen={maxPricePopoverIsOpen}
                        setMaxPricePopoverIsOpen={setMaxPricePopoverIsOpen}
                        index={key}
                      />
                    </td>
                  ))}
              </tr>
            ))}
          </RenderIf>
        }
      />
    </React.Fragment>
  );
}

export default LiveListingTable;
