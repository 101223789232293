import React from "react";
import { generateDescriptions } from "../../../constants";

const ListingDescription = ({ marketplace }) => {
  const description = generateDescriptions(marketplace);
  return (
    <div className="d-flex flex-column">
      <h5>For More Information about Bulk Listings Columns see here: </h5>
      <ul>
        {description.map((x, key) => (
          <li key={"_sub_title_" + key} className="m-2">
            <h6 style={{ display: "inline" }}>
              <u>{x?.subHeading}</u>
            </h6>
            {" - "}
            {x.content}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListingDescription;
