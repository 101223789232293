import * as Yup from "yup";
import { useFormik } from "formik";
import { Alert, Card, CardBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty, cloneDeep, omit, set } from "lodash";
import {
  addListing,
  getListingDetail,
  resetListingDetailStates,
} from "../../../store/actions";
import { checkIfAmazon, optionData } from "./common";
import PillBtn from "../../../components/Common/PillBtn";
import Preloader from "../../../components/Common/Preloader";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import SeeMoreText from "../../../components/Common/SeeMoreText";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import { getDropdownValues, RenderIf } from "../../../utils/common";
import StoreSelector from "../../../components/Common/StoreSelector";
import FormInputField from "../../../components/Common/Form/FormInputField";
import FormFieldWrapper from "../../../components/Common/Form/FormFieldWrapper";
import { amazonListAs, MARKET_PLACES, walmartListAs } from "../../../constants";
import InformationTableInCard from "../../../components/Common/InformationTableInCard";

const validationSchema = Yup.object().shape({
  store_front: Yup.object().required("Store is required"),
  list_as: Yup.object().required("List As is required"),
  asin: Yup.string().when("store_front.marketplace", {
    is: MARKET_PLACES.amazon,
    then: Yup.string()
      .required("ASIN is required")
      .matches(/^[A-Za-z0-9]{10}$/, "ASIN must be a 10-character alphanumeric string"),
    otherwise: Yup.string().nullable(),
  }),
  upc: Yup.string().when("store_front.marketplace", {
    is: MARKET_PLACES.walmart,
    then: Yup.string()
      .required("UPC is required")
      .matches(/^[0-9]{12}$/, "UPC must be a 12-digit barcode"),
    otherwise: Yup.string().nullable(),
  }),
  sku: Yup.string().nullable().max(500, "Length of SKU at max can be of 500"),
  listed_price: Yup.number()
    .required("Listed Price is required")
    .min(0.01, "Listed Price must be a minimum of 0.01")
    .test(
      "is-decimal",
      "Listed Price must have at most two decimal places",
      (value) => {
        if (value === undefined || value === null) return true;
        return /^\d+(\.\d{1,2})?$/.test(value.toString());
      },
    ),
  shipping_weight: Yup.number().optional().min(1, "Shipping Weight must be minimum of 1"),
});

const removeFieldsBasedOnMarketplace = ({ item, marketplace }) => {
  let reqBody = cloneDeep(item);
  if (checkIfAmazon(marketplace)) {
    reqBody = omit(item, ["upc", "shipping_weight"]);
  } else if (marketplace === MARKET_PLACES.walmart) {
    reqBody = omit(item, ["asin"]);
  }
  reqBody = omit(reqBody, ["list_as", "store_front"]);
  set(reqBody, "fulfillment_type", item.list_as.value);

  return reqBody;
};

const getDestructuredStore = ({ values }) => {
  const storeFront = get(values, "store_front");
  if (isEmpty(storeFront)) {
    return;
  }
  const selectedPlatform = get(storeFront, "marketplace");
  const mpAccountId = get(storeFront, "identifier");
  return { selectedPlatform, mpAccountId };
};

const NewAddListingsForm = ({ setIsAddListingFormOpen, selectedStoreFront }) => {
  const defaultListAs = { label: "FBM", value: "FBM" };
  const [seeMore, setSeeMore] = useState("");
  const dispatch = useDispatch();
  const [isItemSubmitted, setIsItemSubmitted] = useState(false);
  const { loading, successMsg, error, listingDetail } = useSelector((state) => state.Repricer);
  const formik = useFormik({
    initialValues: {
      store_front: selectedStoreFront || "",
      list_as: defaultListAs,
      asin: "",
      upc: "",
      sku: "",
      listed_price: 0,
      shipping_weight: 1,
    },
    validationSchema,
    onSubmit: () => {
      const { selectedPlatform, mpAccountId } = getDestructuredStore({ values });
      if (!selectedPlatform || !mpAccountId) {
        return;
      }
      const isAmazon = checkIfAmazon(selectedPlatform);
      const identifier = get(values, isAmazon ? "asin" : "upc");
      setIsItemSubmitted(true);
      dispatch(getListingDetail(selectedPlatform, mpAccountId, identifier));
    },
  });
  const { values, errors, touched, handleSubmit, setFieldValue, setErrors, setTouched, resetForm } = formik;
  const label = get(values, "list_as.label");
  const marketplace = get(values, "store_front.marketplace");
  const isMarketplaceAmazon = checkIfAmazon(marketplace);

  const listItem = async () => {
    const body = removeFieldsBasedOnMarketplace({ item: values, marketplace });
    const { selectedPlatform, mpAccountId } = getDestructuredStore({ values });
    if (!selectedPlatform || !mpAccountId || !body) {
      return;
    }
    dispatch(addListing(selectedPlatform, mpAccountId, body));
  };

  const removeFieldsFromObject = (originalObject, fieldsToRemove, setFunction) => {
    const updatedObj = { ...originalObject };
    fieldsToRemove.forEach((field) => delete updatedObj[field]);
    setFunction(updatedObj);
  };

  const resetInternalStates = () => {
    setIsItemSubmitted(false);
  };

  const completeFormReset = () => {
    resetForm();
    resetInternalStates();
  };

  const handleFormClose = () => {
    completeFormReset();
    setIsAddListingFormOpen(false);
  };

  const clearAllErrors = () => {
    setErrors({});
    setTouched({});
  };

  const clearErrorsAndTouched = (fieldsToRemove) => {
    removeFieldsFromObject(errors, fieldsToRemove, setErrors);
    removeFieldsFromObject(touched, fieldsToRemove, setTouched);
  };

  useEffect(() => {
    dispatch(resetListingDetailStates());
  }, []);

  useEffect(() => {
    if (isItemSubmitted) {
      completeFormReset();
    }
  }, [successMsg]);

  return (
    <>
      <RenderIf isTrue={loading}>
        <Preloader />
      </RenderIf>
      <Breadcrumb
        title={
          <span className="d-flex align-items-center font-weight-bold font-size-16">
            <i
              className="text-primary bx bx-sm bx-left-arrow-alt cursor-pointer mr-2"
              onClick={() => {
                handleFormClose();
              }}
            />
            <span className="headings-text" >ADD LISTINGS</span>
          </span>
        }
      />
      <RenderIf isTrue={successMsg}>
        <div className="auto-hide">
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {successMsg}
          </Alert>
        </div>
      </RenderIf>
      <RenderIf isTrue={error}>
        <div className="auto-hide">
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        </div>
      </RenderIf>
      <Card style={{ borderRadius: "7px" }}>
        <CardBody style={{ minHeight: "542px" }}>
          <div className="container-fluid">
            <div className="row">
              <FormFieldWrapper error={touched.store_front && errors.store_front}>
                <StoreSelector
                  value={values.store_front}
                  onChange={(value) => {
                    clearAllErrors();
                    resetInternalStates();
                    setFieldValue("store_front", value);
                    setFieldValue("list_as", defaultListAs);
                  }}
                  forRepricer={true}
                  isValid={true}
                  optionData={optionData}
                  formStyle={{ marginBottom: "0" }}
                  selectLabel={<span>Store</span>}
                  placeholder={"Select Store"}
                  setWidth={false}
                  includeAllAccountsOption={false}
                />
              </FormFieldWrapper>
              {marketplace && (
                <FormFieldWrapper error={touched.list_as && errors.list_as}>
                  <SimpleSelect
                    className="m-0"
                    label={<span>List As</span>}
                    options={getDropdownValues(isMarketplaceAmazon ? amazonListAs : walmartListAs)}
                    onChange={(value) => {
                      setFieldValue("list_as", value);
                      clearErrorsAndTouched(["sku", "listed_price", "shipping_weight"]);
                    }}
                    value={values.list_as}
                    placeholder={"Select"}
                    classNamePrefix="select2-selection"
                    disabled={!isMarketplaceAmazon}
                    components={!isMarketplaceAmazon && {
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </FormFieldWrapper>
              )}
              {marketplace && (
                <FormFieldWrapper error={isMarketplaceAmazon ? touched.asin && errors.asin : touched.upc && errors.upc}>
                  <FormInputField
                    label={isMarketplaceAmazon ? "ASIN" : "UPC"}
                    placeholder={isMarketplaceAmazon ? "ASIN" : "UPC"}
                    value={isMarketplaceAmazon ? values.asin : values.upc}
                    setFieldValue={setFieldValue}
                    fieldValue={isMarketplaceAmazon ? "asin" : "upc"}
                    postfix={<i className="fas fa-times"></i>}
                    postfixColour={"#e51100"}
                    onClickPostFix={() => {
                      setFieldValue("upc", "");
                      setFieldValue("asin", "");
                      setFieldValue("sku", "");
                      setFieldValue("listed_price", 0.01);
                      resetInternalStates();
                    }}
                    disabled={isItemSubmitted}
                  />
                </FormFieldWrapper>
              )}
              {label && marketplace && (
                <FormFieldWrapper error={touched.sku && errors.sku}>
                  <FormInputField
                    label={"SKU"}
                    extendedLabel={"(Optional)"}
                    placeholder={"SKU"}
                    value={values.sku}
                    setFieldValue={setFieldValue}
                    fieldValue={"sku"}
                  />
                </FormFieldWrapper>
              )}
              {label && marketplace && (
                <FormFieldWrapper error={touched.listed_price && errors.listed_price}>
                  <FormInputField
                    inputType={"number"}
                    label={"Listed Price"}
                    placeholder={"Listed Price"}
                    value={values.listed_price}
                    setFieldValue={setFieldValue}
                    fieldValue={"listed_price"}
                    min={0}
                    prefix={"$"}
                    step={0.01}
                  />
                </FormFieldWrapper>
              )}
              {label && marketplace && !isMarketplaceAmazon && (
                <FormFieldWrapper error={touched.shipping_weight && errors.shipping_weight}>
                  <FormInputField
                    inputType={"number"}
                    label={"Shipping Weight in lb"}
                    extendedLabel={"(Optional)"}
                    placeholder={"Shipping Weight"}
                    value={values.shipping_weight}
                    setFieldValue={setFieldValue}
                    fieldValue={"shipping_weight"}
                    min={1}
                  />
                </FormFieldWrapper>
              )}
            </div>
            <RenderIf isTrue={!isEmpty(listingDetail) && isItemSubmitted}>
              <InformationTableInCard
                cardMessage={"You are about to list this item"}
                infoMessage={`If the item is incorrect, please check the ${
                  isMarketplaceAmazon ? "ASIN" : "UPC"
                } and try again.`}
                tableHeader={
                  <>
                    <th style={{ width: "60%" }}>Item</th>
                    <th style={{ width: "40%" }}>{isMarketplaceAmazon ? "ASIN" : "UPC"}</th>
                  </>
                }
                tableBody={
                  <>
                    <tr className="card-table">
                      <td style={{ width: "60%" }}>
                        <div className="d-flex align-items-center">
                          <div className="mr-2">
                            <img
                              src={listingDetail?.image}
                              style={{ width: "30px", height: "30px" }}
                              alt="product image"
                            />
                          </div>
                          <div>
                            <div>
                              <span className="font-size-12">
                                <SeeMoreText
                                  value={listingDetail?.item_name}
                                  id={"lisitng_description_see_more"}
                                  length={60}
                                  seeMore={seeMore}
                                  setSeeMore={setSeeMore}
                                  onHoverDisable={true}
                                  className= "text-primary"
                                />
                              </span>
                            </div>
                            {isMarketplaceAmazon && (
                              <div>
                                <span className="mr-2 font-size-12">UPC:</span>
                                <span className="headings-text font-size-12">{listingDetail?.upc}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      <td style={{ width: "40%" }}>
                        <span style={{ color: "#556ee6", fontSize: "12px" }}>{listingDetail?.identifier}</span>
                      </td>
                    </tr>
                  </>
                }
              />
              <hr className="border" style={{ color: "#A6B0CF33" }}/>
              <div className="mt-4">
                <div className="font-weight-bold font-size-16 headings-text">
                  {`Do you want to confirm listing this item to ${isMarketplaceAmazon ? "Amazon" : "Walmart"} ?`}
                </div>
                <div className="d-flex align-items-center headings-text flex-wrap mt-3">
                  <RenderIf
                    isTrue={successMsg}
                    fallback={
                      <>
                        <PillBtn
                          className={"waves-effect waves-light mt-2 mr-3"}
                          title={"Cancel"}
                          name={"Cancel"}
                          color={"primary"}
                          outline
                          onClick={resetInternalStates}
                          disabled={loading}
                        />
                        <PillBtn
                          className={"mr-2 mt-2"}
                          title={`List to ${isMarketplaceAmazon ? "Amazon" : "Walmart"}`}
                          name={`List to ${isMarketplaceAmazon ? "Amazon" : "Walmart"}`}
                          color={"primary"}
                          onClick={listItem}
                          disabled={loading}
                        />
                      </>
                    }
                  >
                    <PillBtn
                      className={"waves-effect waves-light mt-2 mr-2"}
                      title={"Back To Listings"}
                      name={"Back To Listings"}
                      color={"primary"}
                      outline
                      onClick={() => {
                        handleFormClose();
                      }}
                      disabled={loading}
                      style={{ width: "138px", height: "38px", borderRadius: "5px", border: "2px solid" }}
                    />
                    <PillBtn
                      className={"mr-2 mt-2"}
                      title={"List Another Item"}
                      name={"List Another Item"}
                      color={"success"}
                      onClick={completeFormReset}
                      disabled={loading}
                      style={{ width: "148px", height: "38px", borderRadius: "6px" }}
                    />
                  </RenderIf>
                </div>
              </div>
            </RenderIf>
          </div>
          <RenderIf isTrue={isEmpty(listingDetail) || !isItemSubmitted}>
            <hr className="border" style={{ color: "#A6B0CF33" }}/>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div>
                <PillBtn
                  className={"waves-effect waves-light mt-2 mr-2"}
                  title={"Back To Listings"}
                  name={"Back To Listings"}
                  color={"primary"}
                  outline
                  onClick={() => {
                    handleFormClose();
                  }}
                  disabled={loading}
                  style={{ width: "138px", height: "38px", borderRadius: "5px", border: "2px solid" }}
                />
              </div>
              <div>
                <PillBtn
                  className={"mr-2 mt-2"}
                  title={"Add Listing"}
                  name={"Add Listing"}
                  color={"primary"}
                  onClick={handleSubmit}
                  disabled={loading}
                  style={{ width: "108px", height: "38px", color: "#F8F8F8", borderRadius: "6px" }}
                />
              </div>
            </div>
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
};

export default NewAddListingsForm;
