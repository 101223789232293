import React from "react";
import { FormGroup } from "reactstrap";
import { RenderIf } from "../../../utils/common";

const FormFieldWrapper = ({ className = "col-12 col-sm-6 col-md-4 col-lg-4 mt-4", children, error }) => {
  return (
      <div className={className}>
        <FormGroup>
          {children}
          <RenderIf isTrue={error}>
            <div className="text-danger">{error}</div>
          </RenderIf>
        </FormGroup>
      </div>
  );
};

export default FormFieldWrapper;
