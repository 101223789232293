import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Alert,
  Row,
  Button,
  Dropdown,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from "reactstrap";
import { useSelector, connect } from "react-redux";
import queryString from "query-string";
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ConfirmDialogBtn } from "../../components/Common/ConfirmDialog";
import Alert1 from "./Alert1";
import {
  LISTINGS_PAGES,
  FACEBOOK_PAGES,
  LISTING_SEARCH_TYPES,
  BULK_SELECT_TYPES,
  MARKET_PLACES,
  USER_ROLES,
} from "../../constants";
import { getObjectforBulk, isDisableBulkAction, getSingleListingProps } from "./Helpers/common";
import CustomPagination from "../../components/Common/CustomPagination";
import BulkListingsUpdateModal from "./Components/BulkListingsUpdateModal";
import FilterTags from "./Components/FilterTags";
import Select, { components } from "react-select";
import { toPascalCase, getUserInfo, verifyRole, localeNum, RenderIf, decodeSKU } from "../../utils/common";
import CustomFilter from "../../components/Common/Filters";
import PageNotFound from "../../assets/images/listing_not_found.svg";
import { nth, last, merge, entries, map, values, omit, find, keys, pick, size, orderBy, first, some } from "lodash";

// actions
import {
  fetchListings,
  fetchUsers,
  fetchMatchingPlatforms,
  fetchShippingTemplate,
  assignShippingTemplate,
  setPreloader,
  setSupplierModal,
  resetFetchError,
  setSelectedListings,
  setListingsBulkUpdateModal,
  setListingSuppliersCollapse,
  fetchSuppliers,
  setActionSupplier,
  setShippingTemplateUpdateModal,
  setSelectedLiveListing,
  updateBrandRestrictionBulk,
  relistRetiredListings,
  bulkDeleteListings,
  updateAOStatus,
  showCustomFiltersAction,
  downloadListings,
  setCustomFiltersAction,
  setMaPriceModal,
  setTwoStepUpdateModal,
  setTwoStepModal,
  fetchPlatforms,
  resetCustomFiltersAction,
} from "../../store/actions";
import ErroredListing from "./Components/ErroredListing";
import LiveListing from "./Components/LiveListing";
import InProgressListing from "./Components/InProgressListing";
import RetiredListing from "./Components/RetiredListing";
import getBrandImageSrc from "../../utils/brandImages";
import ArchiveBadge from "../../components/Common/ArchiveBadge";

const { Option } = components;

const Listings = (props) => {
  const { sku: paramSKU } = useParams();
  const isAllowed = verifyRole("admin", "owner");
  const DEFAULT_HIDDEN_COLS = ["Shipping Template", "Verified Match", "Brand", "UPC"];
  const queryParameters = queryString.parse(props.location.search);
  const { accounts, loading: AccountLoading } = useSelector((state) => state.MarketPlaceAccount);
  const { platforms: platformSuppliers } = useSelector((state) => state.Platform);
  const { account: tenantObj } = useSelector((state) => state.Settings);
  const isTwoStepOn = tenantObj?.data?.two_step_feature;
  const [type, setType] = useState("");
  const [search, setSearch] = useState("");
  const [allStoresObj, setAllStoresObj] = useState({});
  const [tableHeaders, setTableHeaders] = useState([]);
  const [downloadDropDown, setDownloadDropDown] = useState(false);
  const [storeFront, setStoreFront] = useState({ label: "", value: "" });
  const [columns, setColumns] = useState({ Check: false, Uncheck: true });
  const [selectColumnsDropDown, setSelectColumnsDropDown] = useState(false);
  const [listingsPage, setListingsPage] = useState({ label: "", value: "" });
  const [bulkSelectType, setBulkSelectType] = useState(BULK_SELECT_TYPES.current_page);
  const [searchType, setSearchType] = useState({ label: "All", value: LISTING_SEARCH_TYPES.All });
  const isValid = !!accounts?.data?.filter((x) => x.valid && x.enabled).length;
  const [bulkUpdateDropDown, setBulkUpdateDropDown] = useState(false);
  const marketplaceAccounts = new Set(
    accounts?.data.reduce((acc, account) => {
      if (account.valid && account.enabled) acc.push(account.marketplace);
      return acc;
    }, []),
  );

  const initialFilters = {
    page_number: queryParameters?.page || 1,
    per_page: 30,
    partner_id: "",
    filters_hash: {
      filters: [],
      sortOptions: {},
    },
    platform: MARKET_PLACES.walmart,
  };

  const [filters, setFilters] = useState(initialFilters);
  const downloadFilters = useRef(filters);
  const missingSuppliers = useRef(filters);
  const partner = useRef(storeFront.value);

  const ListingIds = props.Listings.listings?.results?.map((x) => ({
    id: x?.id,
    partner_id: x.partner_id,
    sku: x.sku,
  }));

  const isListingSupplier = paramSKU && queryParameters?.platform && queryParameters?.storeId;
  const isShopify = storeFront?.marketplace === MARKET_PLACES.shopify;
  const findStoreHelper = (store) =>
    store.marketplace === queryParameters?.platform && store?.id === +queryParameters?.storeId;

  const StoreOption = (props) => {
    const isValid = props.data.valid && props.data.enabled;
    return (
      <Option {...props}>
        <span className="d-flex align-items-center">
          {isValid && getBrandImageSrc(props.data.marketplace)
            ? (
            <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
              )
            : (
            <i className="mr-2 bx bx-sm bx-x text-danger" />
              )}
          {props.data.label}
          {props.data.is_archive && ArchiveBadge()}
        </span>
      </Option>
    );
  };

  function selectAllListings () {
    props.setSelectedListings(
      ListingIds?.map((item) => ({
        id: item?.id,
        partner_id: item.partner_id,
        sku: item.sku,
      })),
    );
  }

  function getTableHeaders (headers) {
    if ([MARKET_PLACES.amazon, MARKET_PLACES.walmart].includes(storeFront.marketplace)) {
      setTableHeaders(
        headers.filter((x) => {
          if ((x.for && storeFront.marketplace !== x.for) || (x.user && x.user)) return false;
          return true;
        }),
      );
    } else {
      const filterData = headers.filter((x) => !x.hide);
      setTableHeaders(
        filterData.filter((x) => {
          if ((x.for && storeFront.marketplace !== x.for) || (x.user && x.user)) return false;
          return true;
        }),
      );
    }
    setColumns(
      merge(
        columns,
        headers.reduce((acc, x) => {
          if (DEFAULT_HIDDEN_COLS.includes(x.title)) acc[x.title] = false;
          else acc[x.title] = true;
          return acc;
        }, {}),
      ),
    );
  }

  function handleUpdateBulkListings () {
    props.setListingsBulkUpdateModal(true);
  }

  function handleTemplateModal (type) {
    setType(type);
    props.setShippingTemplateUpdateModal(true);
  }

  function handlePushPendingListingBulk () {
    props.updateBrandRestrictionBulk(
      getObjectforBulk(storeFront.marketplace, props.Listings.selectedListings, ["partner_id", "skus", "status"], true),
      { listings_page: listingsPage.value },
    );
  }

  function handleDeleteBulkListings (isDelete) {
    const fetchParams = {
      ...filters,
      partner_id: storeFront.value,
      listings_page: listingsPage.value,
      platform: storeFront.marketplace,
    };
    let data = { [isDelete ? "delete_type" : "select_type"]: bulkSelectType, listings_page: listingsPage.value };
    let params = {};
    if (bulkSelectType === BULK_SELECT_TYPES.all_listings) {
      params = omit(filters, ["column_index", "page_number", "per_page"]);
      data.va_id = getUserInfo()?.id;
    } else {
      data = {
        ...data,
        ...getObjectforBulk(storeFront.marketplace, props.Listings.selectedListings, ["partner_id", "skus"]),
      };
    }
    if (isDelete) props.bulkDeleteListings(data, params, fetchParams);
    else
      props.relistRetiredListings(
        merge(data, { platform: storeFront.marketplace, partner_id: storeFront.value }),
        fetchParams,
      );
    setBulkSelectType(BULK_SELECT_TYPES.current_page);
  }

  const getStoreFrontById = (id) => accounts?.data?.find((x) => x.id === id);
  const getStoreFrontId = (_) => filters.partner_id;

  function handleDownloadListings () {
    downloadFilters.current = filters;
    downloadFilters.current.partner_id = getStoreFrontId();
    downloadFilters.current.listings_page = listingsPage.value;
    props.downloadListings(downloadFilters.current);
  }

  function handleMissingSuppliersDownload () {
    missingSuppliers.current = {
      ...filters,
      va_id: getUserInfo()?.id,
      partner_id: storeFront.value,
      filters_hash: {
        ...filters.filters_hash,
        filters: [
          {
            field: "platform_id",
            operator: "=",
            value: props.Listings.listings.platforms?.find((x) => x.name === "No Supplier Link")?.id,
            label: "Select Platform",
          },
        ],
      },
    };
    missingSuppliers.current.partner_id = getStoreFrontId();

    props.downloadListings(missingSuppliers.current);
  }

  function handleSearchSubmit (e) {
    if (search) {
      e.preventDefault();
      if (props.Supplier.listingSupplierCollapse || isListingSupplier) {
        props.setListingSuppliersCollapse(false);
        props.setSelectedLiveListing({});
      }
      if (isListingSupplier) props.history.replace("/listings");
      setFilters({
        ...filters,
        page_number: 1,
        filters_hash: {
          ...filters.filters_hash,
          filters: [
            ...filters.filters_hash.filters.filter((x) => !values(LISTING_SEARCH_TYPES).includes(x.field)),
            {
              field: searchType.value,
              ...(searchType.value === LISTING_SEARCH_TYPES.All ? {} : { operator: "=" }),
              value: search.trim(),
            },
          ],
        },
      });
    } else e.preventDefault();
  }

  function getFiltersHash (sku) {
    return {
      ...filters.filters_hash,
      filters: [
        ...filters.filters_hash.filters,
        {
          field: LISTING_SEARCH_TYPES.SKU,
          operator: "=",
          value: encodeURIComponent(sku),
        },
      ],
    };
  }

  function handleSupplier (item, key, formik, isShowListings = false) {
    if (
      (isShowListings && !keys(props.Supplier.listing).length) ||
      (props.Supplier.suppliers?.length && props.Listings.selectedLiveListing.key !== key)
    ) {
      props.fetchSuppliers({
        platform: storeFront.marketplace || item.platform,
        sku: decodeSKU(item?.sku),
        item_id: item.sku,
        partner_id: item?.partner_id,
        is_admin: getUserInfo()?.role === USER_ROLES.admin,
        ...(isShowListings ? { show_listing: true } : {}),
      });
    }

    if (formik && props.Supplier.actionSupplier) {
      props.setActionSupplier("");
      formik.handleReset();
    }
    props.setSelectedLiveListing({
      key,
      platform: storeFront.marketplace || item.platform,
      sku: decodeSKU(item?.sku),
      storeId: item?.partner_id,
      item_id: item?.item_id,
    });
    props.setListingSuppliersCollapse(
      !(props.Listings.selectedLiveListing?.key === key && props.Supplier.listingSupplierCollapse === true),
    );
  }

  useEffect(() => {
    props.fetchUsers({
      account_id: getUserInfo()?.account_id,
      pagination: false,
    });
    queryParameters?.filter === "missing_suppliers" && listingsPage.value === "live" && props.fetchMatchingPlatforms();
    !platformSuppliers?.data?.length && props.fetchPlatforms({});
    props.resetCustomFiltersAction();
    props.showCustomFiltersAction(false);
  }, []);

  useEffect(() => {
    isValid && props.setSelectedListings([]);
  }, [filters.page_number]);

  useEffect(() => {
    if (listingsPage.value) {
      const activePlatform = isListingSupplier
        ? accounts?.data?.find(findStoreHelper)
        : getStoreFrontById(partner?.current) || first(orderBy(accounts?.data, ["enabled", "valid"], ["desc", "desc"]));
      partner.current = activePlatform?.id;
      setStoreFront({
        label: activePlatform?.name,
        value: activePlatform?.id,
        ...pick(activePlatform, "marketplace", "enabled", "valid"),
      });
      setFilters({
        ...filters,
        platform: activePlatform?.marketplace,
      });
    }
  }, [listingsPage.value]);

  useEffect(() => {
    isValid &&
      props.setPreloader(some([props.Listings.loading, props.Supplier.loading, props.Listings.shippingTemplateLoading], Boolean));
  }, [props.Listings.loading, props.Supplier.loading, props.Listings.shippingTemplateLoading]);
  useEffect(() => {
    if (Array.isArray(accounts?.data) && !Object.keys(allStoresObj).length) {
      setAllStoresObj(accounts?.data.reduce((obj, acc) => merge(obj, { [acc?.id]: acc }), {}));
    }

    if (!isListingSupplier && accounts?.data && !props.Listings.loading) {
      const activePlatform = getStoreFrontById(filters.partner_id);
      if (!storeFront.value && activePlatform && listingsPage.value) {
        setStoreFront({
          label: activePlatform.name,
          value: activePlatform?.id,
          ...pick(activePlatform, "marketplace", "enabled", "valid"),
        });
        partner.current = activePlatform?.id;
        setFilters({
          ...filters,
          platform: activePlatform.marketplace,
        });
      }

      if (!listingsPage.label) {
        setListingsPage({
          label: nth(LISTINGS_PAGES, 1).label,
          value: nth(LISTINGS_PAGES, 1).value,
        });
      }

      // If search is present but filter is removed from filter tags
      if (search && filters.filters_hash.filters.every((x) => !values(LISTING_SEARCH_TYPES).includes(x.field)))
        setSearch("");

      if (
        queryParameters.filter === "missing_suppliers" &&
        listingsPage.value === "live" &&
        props.Listings.matchingPlatforms
      ) {
        const unknownSupplier = props.Listings.matchingPlatforms?.find((x) => x.name === "unknown")?.id;
        filters.filters_hash.filters = [
          {
            field: "platform_id",
            operator: "=",
            value: unknownSupplier,
            label: "Select Platform",
          },
        ];
        setFilters({ ...filters });
        props.setCustomFiltersAction({ platform: { value: unknownSupplier, label: "No Supplier Link" } });
      }

      if (listingsPage.label && marketplaceAccounts.size === 1 && marketplaceAccounts.has("amazon") && !storeFront) {
        const account = last(accounts?.data);
        partner.current = account?.id;
        setStoreFront({
          label: account.name,
          value: account.id,
          ...pick(account, "marketplace", "enabled", "valid"),
        });

        const obj = filters?.filters_hash?.filters?.find((x) => x.field === "keyword");
        if (obj) obj.value = encodeURIComponent(obj.value);
        props.fetchListings({
          ...filters,
          partner_id: partner.current,
          listings_page: listingsPage.value,
          platform: MARKET_PLACES.amazon,
        });
        setFilters({
          ...filters,
          partner_id: partner.current,
          listings_page: listingsPage.value,
          platform: MARKET_PLACES.amazon,
        });
      } else if (listingsPage.label) {
        const obj = filters?.filters_hash?.filters?.find((x) => x.field === "keyword");
        if (obj) obj.value = encodeURIComponent(obj.value);
        const storeFront = getStoreFrontById(partner?.current);
        if (partner.current) {
          props.fetchListings({
            ...filters,
            partner_id: partner?.current,
            listings_page: listingsPage?.value,
            platform: storeFront?.marketplace,
          });
          setFilters({
            ...filters,
            partner_id: partner?.current,
            listings_page: listingsPage?.value,
            platform: storeFront?.marketplace,
          });
        }
      }
      !props.Listings.templates && !props.Listings.shippingTemplateLoading && props.fetchShippingTemplate();
    }
  }, [filters, accounts, listingsPage.value, props.Listings.matchingPlatforms]);

  useEffect(() => {
    if (accounts?.data && isListingSupplier) {
      if (!props.Supplier.success) {
        const account = accounts?.data?.find(findStoreHelper);
        account &&
          setStoreFront({
            label: account.name,
            value: account?.id,
            ...pick(account, "marketplace", "enabled", "valid"),
          });
        account &&
          handleSupplier(
            { sku: paramSKU, partner_id: queryParameters?.storeId, platform: account.marketplace },
            0,
            undefined,
            true,
          );
        !filters.filters_hash.filters.length &&
          setFilters({
            ...filters,
            filters_hash: getFiltersHash(decodeSKU(paramSKU)),
          });
      }
      if (props.Supplier.platforms.length) {
        const listingsPage = find(LISTINGS_PAGES, (x) => x.value === props.Supplier.listings_page);
        setListingsPage({ label: listingsPage.label, value: listingsPage.value });
      }
    }
  }, [props.Supplier.platforms, accounts?.data]);

  const noRecords =
    !props.Listings.listings?.results?.length &&
    !props.Listings.loading &&
    isValid &&
    !AccountLoading &&
    !isListingSupplier;

  const GetMessage = () => {
    if (
      !isValid &&
      !AccountLoading &&
      !props.Listings.loading &&
      !props.Supplier.loading &&
      !props.Listings.shippingTemplateLoading &&
      props.Listings.listings === undefined &&
      !props.Layout.preloaderIsopen
    )
      return (
        <div className="text-center">
          <img src={PageNotFound} alt="no-record-found" />
          <div className="text-center text-white mb-4 h4 mt-3">
            None of your accounts are active. Please go here to fix this.
          </div>
          <Button onClick={() => props.history.push("/settings")} color="primary">
            Manage your Accounts
          </Button>
        </div>
      );

    if (noRecords) return <h2 className="text-center">No Records Found</h2>;
    else return null;
  };

  return (
    <React.Fragment>
      {props.Supplier?.success.length ? (
        <div className="auto-hide">
          <Alert color="success" className="my-1">
            <i className="bx bx-info-circle pr-2"></i>
            {props.Supplier?.success || ""}
          </Alert>
        </div>
      ) : null}

      {props.Supplier?.error && (
        <div className="auto-hide">
          <Alert color="danger" className="my-1">
            <i className="bx bx-info-circle pr-2"></i>
            {props.Supplier?.error || ""}
          </Alert>
        </div>
      )}

      {props.Listings.success && (
        <div className="auto-hide">
          <Alert color="success" className="my-1">
            <i className="bx bx-info-circle pr-2"></i>
            {props.Listings.success || ""}
          </Alert>
        </div>
      )}

      {props.Listings.error && (
        <div className="auto-hide">
          <Alert color="danger" className="my-1">
            <i className="bx bx-info-circle pr-2"></i>
            {props.Listings.error || ""}
          </Alert>
        </div>
      )}
      {props.Listings.listingsBulkUpdateModalIsOpen && (
        <BulkListingsUpdateModal type="bulk listing" platform={storeFront.marketplace} />
      )}

      {props.Listings.shippingTemplateUpdateModalIsOpen && (
        <BulkListingsUpdateModal
          type={type}
          storeFront={storeFront.value}
          selectType={bulkSelectType}
          filters={filters}
          platform={storeFront.marketplace}
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Listings"
            // eslint-disable-next-line react/no-children-prop
            children={
              <Row className="align-items-center d-flex justify-content-end mr-1">
                {props.Listings.selectedListings?.length ? (
                  <>
                    <RenderIf isTrue={listingsPage.value === "errored" && !isShopify}>
                      <ConfirmDialogBtn
                        button={{
                          color: "primary",
                          title: "Bulk Restricted Overrides",
                          tooltip:
                            "We have restricted some brands because they are likely to cause IP issues which could ultimately result in suspension. If you would like to list items from these brand anyway, you can do so using this feature.",
                        }}
                        msg="To list anyway even though brand is restricted"
                        onConfirm={handlePushPendingListingBulk}
                      />
                    </RenderIf>
                    <RenderIf isTrue={listingsPage.value === "live" && !isShopify}>
                      <ButtonDropdown
                        isOpen={bulkUpdateDropDown}
                        toggle={() => setBulkUpdateDropDown(!bulkUpdateDropDown)}
                        className="ml-2"
                      >
                        <DropdownToggle caret color="warning">
                          Bulk Update
                          <i className="bx bx-xs bx-edit ml-1" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {[BULK_SELECT_TYPES.current_page, BULK_SELECT_TYPES.all_listings].includes(bulkSelectType) && (
                            <DropdownItem onClick={handleUpdateBulkListings}>Status</DropdownItem>
                          )}

                          {(storeFront.marketplace === MARKET_PLACES.walmart ||
                            (getUserInfo()?.account_id === 2 && storeFront.marketplace !== "facebook")) && (
                            <DropdownItem onClick={() => handleTemplateModal("template")}>
                              Shippment Template
                            </DropdownItem>
                          )}

                          {isTwoStepOn &&
                            !allStoresObj[storeFront.value]?.two_step_enabled &&
                            bulkSelectType === BULK_SELECT_TYPES.all_listings && (
                              <DropdownItem onClick={() => handleTemplateModal("2-step")}>Shipment Type</DropdownItem>
                          )}
                        </DropdownMenu>
                      </ButtonDropdown>
                    </RenderIf>
                    <RenderIf isTrue={listingsPage.value === "retired"}>
                      <ConfirmDialogBtn
                        button={{
                          color: "primary",
                          title: "Relist Listings",
                          icon: "bx bxs-paper-plane",
                          disabled: isDisableBulkAction(
                            props.Listings.selectedListings,
                            props.Listings.listings.results,
                          ),
                        }}
                        msg={
                          <>
                            <p>This will relist only retired/deleted selected listings!</p>
                            <p>This action cannot be undone!</p>
                          </>
                        }
                        onConfirm={() => handleDeleteBulkListings(false)}
                      />
                    </RenderIf>
                    <RenderIf isTrue={listingsPage.value !== "in_progress"}>
                      <ConfirmDialogBtn
                        button={{
                          color: "danger",
                          title: "Delete Listings",
                          icon: "bx bx-xs bx-trash",
                          disabled:
                            listingsPage.value === "retired"
                              ? isDisableBulkAction(props.Listings.selectedListings, props.Listings.listings.results)
                              : false,
                        }}
                        msg={
                          <>
                            <p>This will delete and retire selected listings!</p>
                            <p>This action cannot be undone!</p>
                          </>
                        }
                        onConfirm={() => handleDeleteBulkListings(true)}
                      />
                    </RenderIf>
                  </>
                ) : (
                  <>
                    <RenderIf isTrue={listingsPage.value === "in_progress"}>
                      <Alert1 color="primary" className="mr-3">
                        <i className="bx bx-info-circle pr-2"></i>
                        Items may take up to 30 mins to get fully listed to the marketplace
                      </Alert1>
                    </RenderIf>
                    <RenderIf isTrue={size(accounts?.data) > 1}>
                      <FormGroup className="select2-container mt-3 mr-3 w-80">
                        <Select
                          name="marketplace_account"
                          value={storeFront}
                          onChange={(e) => {
                            if (isListingSupplier) {
                              props.history.replace("/listings");
                              props.setListingSuppliersCollapse(false);
                              props.setSelectedLiveListing({});
                            }
                            props.setSelectedListings([]);
                            setFilters({ ...filters, partner_id: e.value, page_number: 1, platform: e.marketplace });
                            setStoreFront(pick(e, "label", "value", "marketplace", "valid", "enabled"));
                            partner.current = e.value;
                          }}
                          options={accounts?.data.reduce((acc, x) => {
                            acc.push({
                              label: x?.name,
                              value: x?.id,
                              marketplace: x?.marketplace,
                              valid: x?.valid,
                              enabled: x?.enabled,
                              is_archive: x.is_archive,
                            });
                            return acc;
                          }, [])}
                          classNamePrefix="select2-selection"
                          components={{ Option: StoreOption }}
                        />
                      </FormGroup>
                    </RenderIf>
                    <FormGroup className="select2-container mt-3 mr-3" style={{ width: "200px" }}>
                      <Select
                        name="listing_page"
                        value={listingsPage}
                        onChange={(e) => {
                          if (isListingSupplier) {
                            props.history.replace("/listings");
                            props.setListingSuppliersCollapse(false);
                            props.setSelectedLiveListing({});
                          }
                          setListingsPage({ label: e.label, value: e.value });
                          setFilters(initialFilters);
                        }}
                        options={storeFront.marketplace === "facebook" ? FACEBOOK_PAGES : LISTINGS_PAGES}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <div className="separation-line-listing mr-3" />
                    <Link to="/listings_csv" target="_blank">
                      <Button color="primary" outline className="waves-effect waves-light mr-3">
                        Add/Remove in Bulk
                      </Button>
                    </Link>
                    <RenderIf isTrue={!isShopify}>
                      <Link to="/listings/new" target="_blank">
                        <Button color="primary">Add Listing</Button>
                      </Link>
                    </RenderIf>
                  </>
                )}
              </Row>
            }
          />
          <Card>
            <CardHeader className="py-2">
              <div className="d-flex justify-content-between flex-wrap  align-items-center">
                <div className="d-flex">
                  <form className="app-search d-lg-block" onSubmit={(e) => handleSearchSubmit(e)}>
                    <input disabled={!isValid} type="submit" hidden />
                    <div className="d-flex inputs-group">
                      <Select
                        name="select_type"
                        value={searchType}
                        isDisabled={!isValid}
                        className="select2-container mb-0 form-group"
                        onChange={(e) => setSearchType({ label: e.label, value: e.value })}
                        options={map(
                          entries(
                            omit(
                              LISTING_SEARCH_TYPES,
                              storeFront.marketplace === MARKET_PLACES.amazon ? ["UPC"] : [""],
                            ),
                          ),
                          ([l, v], i) => ({ label: l, value: v }),
                        )}
                        classNamePrefix="select2-selection"
                      />
                      <div className="vertical-line2" />
                      <div className="position-relative">
                        <i
                          onClick={(e) => handleSearchSubmit(e)}
                          className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"
                        />
                        <input
                          id="search_field"
                          type="text"
                          className="form-control search-input"
                          placeholder="Search..."
                          disabled={!isValid}
                          value={search}
                          onChange={(e) => {
                            if (!e.target.value.trim()) {
                              setSearch("");
                              setFilters({
                                ...filters,
                                page_number: 1,
                                filters_hash: {
                                  ...filters.filters_hash,
                                  filters: [
                                    ...filters.filters_hash.filters.filter(
                                      (x) => !values(LISTING_SEARCH_TYPES).includes(x.field),
                                    ),
                                  ],
                                },
                              });
                            } else setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </form>
                  <RenderIf isTrue={listingsPage.value === "live"}>
                    <div className="d-flex pl-3 align-items-center">
                      <div className="d-flex flex-column">
                        Valid Suppliers
                        <h6 className="mb-0">{localeNum(props.Listings.listings?.known_suppliers_count) || "N/A"}</h6>
                      </div>
                      <div className="d-flex flex-column ml-3">
                        Missing Costs
                        <h6 className="mb-0">{localeNum(props.Listings.listings?.unknown_suppliers_count) || "N/A"}</h6>
                      </div>
                      <div className="d-flex flex-column ml-3">
                        Missing Dimensions Count
                        <h6 className="mb-0">{localeNum(props.Listings.listings?.missing_dimensions_count) || "N/A"}</h6>
                      </div>
                      <div className="d-flex flex-column ml-3">
                        Missing Shipping Estimates Count
                        <h6 className="mb-0">{localeNum(props.Listings.listings?.missing_estimates_count) || "N/A"}</h6>
                      </div>
                    </div>
                  </RenderIf>
                </div>
                <div className="align-items-center d-flex justify-content-end mr-1">
                  <RenderIf isTrue={listingsPage.value === "retired" && !isListingSupplier}>
                    <>
                      <RenderIf isTrue={isAllowed}>
                        <FormGroup className="select2-container mt-3 ml-1" style={{ width: 200 }}>
                          <Select
                            isDisabled={!isValid}
                            isOptionDisabled={!isValid}
                            name="retired_by"
                            value={{
                              value: filters.filters_hash.filters?.find((x) => x.field === "retired_by")?.value || "",
                              label:
                                filters.filters_hash.filters?.find((x) => x.field === "retired_by")?.label ||
                                "Retired/Deleted By",
                            }}
                            onChange={(e) => {
                              if (e.value !== "") {
                                const index = filters.filters_hash.filters?.findIndex((x) => x.field === "retired_by");
                                if (index !== -1) {
                                  filters.filters_hash.filters[index].value = e.value;
                                  filters.filters_hash.filters[index].label = e.label;
                                  setFilters({ ...filters, page_number: 1 });
                                } else {
                                  setFilters({
                                    ...filters,
                                    page_number: 1,
                                    filters_hash: {
                                      ...filters.filters_hash,
                                      filters: [
                                        ...filters.filters_hash.filters,
                                        {
                                          field: "retired_by",
                                          operator: "=",
                                          value: e.value,
                                          label: e.label,
                                        },
                                      ],
                                    },
                                  });
                                }
                              }
                            }}
                            options={props.User.users?.data.reduce((acc, x, i) => {
                              if (props.Listings.listings?.retired_by?.includes(-1) && !acc.length)
                                acc.push({ label: "Retired/Deleted By System", value: -1 });
                              else if (props.Listings.listings?.retired_by?.includes(x?.id))
                                acc.push({ label: toPascalCase(x?.username), value: x?.id, key: i });
                              return acc;
                            }, [])}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </RenderIf>
                      <FormGroup className="select2-container mt-3 ml-1" style={{ width: 200 }}>
                        <Select
                          name="listing_status"
                          isDisabled={!isValid}
                          isOptionDisabled={!isValid}
                          value={{
                            value: filters.filters_hash.filters?.find((x) => x.field === "listing_status")?.value || "",
                            label:
                              filters.filters_hash.filters?.find((x) => x.field === "listing_status")?.label ||
                              "Select a Status",
                          }}
                          onChange={(e) => {
                            if (e.value !== "") {
                              const index = filters.filters_hash.filters?.findIndex(
                                (x) => x.field === "listing_status",
                              );
                              if (index !== -1) {
                                filters.filters_hash.filters[index].value = e.value;
                                filters.filters_hash.filters[index].label = e.label;
                                setFilters({ ...filters, page_number: 1 });
                              } else {
                                setFilters({
                                  ...filters,
                                  page_number: 1,
                                  filters_hash: {
                                    ...filters.filters_hash,
                                    filters: [
                                      ...filters.filters_hash.filters,
                                      {
                                        field: "listing_status",
                                        operator: "=",
                                        value: e.value,
                                        label: e.label,
                                      },
                                    ],
                                  },
                                });
                              }
                            }
                            props.resetFetchError();
                          }}
                          options={Object.entries(props.Listings.listings?.listing_statuses || {}).map((status, i) => ({
                            label: toPascalCase(status[0]?.includes("retired") ? "Retired/Deleted" : status[0]),
                            value: status[1],
                            key: i,
                          }))}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                    </>
                  </RenderIf>
                  <RenderIf isTrue={listingsPage.value === "errored" && !isListingSupplier}>
                    <FormGroup className="select2-container mt-3 ml-1" style={{ width: 200 }}>
                      <Select
                        isDisabled={!isValid}
                        isOptionDisabled={!isValid}
                        name="error_type"
                        value={{
                          value: filters.filters_hash.filters?.find((x) => x.field === "error_type")?.value || "",
                          label:
                            filters.filters_hash.filters?.find((x) => x.field === "error_type")?.label ||
                            "Select a Type",
                        }}
                        onChange={(e) => {
                          if (e.value !== "") {
                            const index = filters.filters_hash.filters?.findIndex((x) => x.field === "error_type");
                            if (index !== -1) {
                              filters.filters_hash.filters[index].value = e.value;
                              filters.filters_hash.filters[index].label = e.label;
                              setFilters({ ...filters, page_number: 1 });
                            } else {
                              setFilters({
                                ...filters,
                                page_number: 1,
                                filters_hash: {
                                  ...filters.filters_hash,
                                  filters: [
                                    ...filters.filters_hash.filters,
                                    {
                                      field: "error_type",
                                      operator: "=",
                                      value: e.value,
                                      label: e.label,
                                    },
                                  ],
                                },
                              });
                            }
                          }
                        }}
                        options={Object.entries(props.Listings.listings?.error_types || {}).map((status, i) => ({
                          label: toPascalCase(status[0]),
                          value: status[1],
                          key: i,
                        }))}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </RenderIf>
                  <RenderIf isTrue={["in_progress", "live"].includes(listingsPage.value)}>
                    <>
                      <Dropdown
                        direction="down"
                        isOpen={downloadDropDown}
                        toggle={() => { setDownloadDropDown(downloadDropDown => !downloadDropDown); }}
                      >
                        <DropdownToggle
                          className={`toc-heading-cursor ml-3 mb-0 ${!isValid && "disabled"}`}
                          data-toggle="dropdown"
                          tag="span"
                          onClick={() => { setDownloadDropDown(downloadDropDown => !downloadDropDown); }}
                        >
                          <i className="bx bx-sm bx-download text-primary ml-4 cursor-pointer" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem key="_download_skus_" onClick={handleDownloadListings}>
                            Download SKUs
                          </DropdownItem>
                          <DropdownItem key="_download_missing_suppliers_" onClick={handleMissingSuppliersDownload}>
                            Download SKUs Missing Costs
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <RenderIf isTrue={["in_progress", "live"].includes(listingsPage.value)}>
                        <ButtonDropdown
                          isOpen={selectColumnsDropDown}
                          toggle={() => setSelectColumnsDropDown(!selectColumnsDropDown)}
                          className="mr-1"
                        >
                          <DropdownToggle tag="i" className="bx bx-sm bx-columns text-primary ml-4 cursor-pointer" />
                          <DropdownMenu className="overflow-auto" style={{ maxHeight: "300px" }}>
                            {columns.Uncheck && (
                              <DropdownItem
                                onClick={() => {
                                  setColumns({
                                    Check: true,
                                    ...tableHeaders.reduce((acc, x) => {
                                      acc[x.title] = false;
                                      return acc;
                                    }, {}),
                                  });
                                }}
                              >
                                Uncheck All
                              </DropdownItem>
                            )}
                            {columns.Check && (
                              <DropdownItem
                                onClick={() =>
                                  setColumns({
                                    Uncheck: true,
                                    ...tableHeaders.reduce((acc, x) => {
                                      acc[x.title] = true;
                                      return acc;
                                    }, {}),
                                  })
                                }
                              >
                                Check All
                              </DropdownItem>
                            )}
                            {tableHeaders
                              .filter((x) => !x.show)
                              .map((header, key) => (
                                <DropdownItem key={`_select_columns_+ ${key}`}>
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={columns[header.title]}
                                      onChange={() => false}
                                    />
                                    <label
                                      className="custom-control-label"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setColumns({ ...columns, [header.title]: !columns[header.title] });
                                      }}
                                    >
                                      {header.title}
                                    </label>
                                  </div>
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </RenderIf>
                      <RenderIf isTrue={!isListingSupplier}>
                        <Button
                          onClick={() => props.showCustomFiltersAction(!props.Listings.showCustomFilters)}
                          className="transparent-button"
                        >
                          <i className="bx bx-sm bx-filter-alt text-primary ml-4 cursor-pointer" />
                        </Button>
                      </RenderIf>
                    </>
                  </RenderIf>
                </div>
              </div>
              <div className="overflow-auto">
                {filters.filters_hash.filters.length ? <FilterTags filters={filters} setFilters={setFilters} /> : null}
              </div>
            </CardHeader>
            <RenderIf isTrue={props.Listings.selectedListings?.length}>
              <div className="blockquote-warning">
                <h6>
                  {bulkSelectType === BULK_SELECT_TYPES.current_page
                    ? props.Listings.selectedListings?.length
                    : props.Listings.listings?.total_count}{" "}
                  listings selected{" "}
                  {bulkSelectType === BULK_SELECT_TYPES.current_page ? "on current page" : "for all pages"}
                </h6>
                <RenderIf
                  isTrue={bulkSelectType === BULK_SELECT_TYPES.all_listings && props.Listings.selectedListings?.length}
                  fallback={
                    <h6
                      className="cursor-pointer"
                      onClick={() => {
                        setBulkSelectType(BULK_SELECT_TYPES.all_listings);
                        selectAllListings();
                      }}
                    >
                      Select All {props.Listings.listings?.total_count} filtered listings (Only few actions will work)
                    </h6>
                  }
                >
                  <h6
                    className="cursor-pointer"
                    onClick={() => {
                      setBulkSelectType(BULK_SELECT_TYPES.current_page);
                      props.setSelectedListings([]);
                    }}
                  >
                    Deselect All {props.Listings.listings?.total_count}
                  </h6>
                </RenderIf>
              </div>
            </RenderIf>
            <CardBody className="px-0">
              <GetMessage />
              <RenderIf isTrue={listingsPage.value === "in_progress" && props.Listings?.listings?.api_status}>
                <Alert color="warning" className="my-1">
                  <i className="bx bx-info-circle pr-2"></i>
                  {props.Listings?.listings?.api_status || ""}
                </Alert>
              </RenderIf>
              <RenderIf isTrue={!noRecords}>
                <>
                  <RenderIf
                    isTrue={
                      listingsPage.value === "in_progress" ||
                      (isListingSupplier && props.Supplier.listings_page === "in_progress")
                    }
                  >
                    <InProgressListing
                      {...getSingleListingProps(isListingSupplier, props.Supplier.listings_page, "in_progress")}
                      allStoresObj={allStoresObj}
                      storeFront={storeFront}
                      filters={filters}
                      setFilters={setFilters}
                      columns={columns}
                      getTableHeaders={getTableHeaders}
                    />
                  </RenderIf>
                  <RenderIf
                    isTrue={
                      listingsPage.value === "errored" ||
                      (isListingSupplier && props.Supplier.listings_page === "errored")
                    }
                  >
                    <ErroredListing
                      {...getSingleListingProps(isListingSupplier, props.Supplier.listings_page, "errored")}
                      listingsPage={listingsPage}
                      selectAllListings={selectAllListings}
                      storeFront={storeFront}
                      setBulkSelectType={setBulkSelectType}
                      ListingIds={ListingIds}
                      allStoresObj={allStoresObj}
                    />
                  </RenderIf>
                  <RenderIf
                    isTrue={
                      listingsPage.value === "retired" ||
                      (isListingSupplier && props.Supplier.listings_page === "retired")
                    }
                  >
                    <RetiredListing
                      {...getSingleListingProps(isListingSupplier, props.Supplier.listings_page, "retired")}
                      storeFront={storeFront}
                      listingsPage={listingsPage}
                      selectAllListings={selectAllListings}
                      setBulkSelectType={setBulkSelectType}
                      ListingIds={ListingIds}
                      allStoresObj={allStoresObj}
                    />
                  </RenderIf>
                  <RenderIf
                    isTrue={
                      listingsPage.value === "live" || (isListingSupplier && props.Supplier.listings_page === "live")
                    }
                  >
                    <LiveListing
                      {...getSingleListingProps(isListingSupplier, props.Supplier.listings_page, "live")}
                      columns={columns}
                      filters={filters}
                      ListingIds={ListingIds}
                      setFilters={setFilters}
                      storeFront={storeFront}
                      handleSupplier={handleSupplier}
                      getTableHeaders={getTableHeaders}
                      isListingSupplier={isListingSupplier}
                      selectAllListings={selectAllListings}
                      setBulkSelectType={setBulkSelectType}
                    />
                  </RenderIf>
                  <RenderIf isTrue={props.Listings?.listings?.total_count}>
                    <CustomPagination
                      total={props.Listings.listings?.total_count}
                      page={props.Listings.listings?.page_number}
                      perPage={props.Listings.listings?.per_page}
                      tabsFilter={filters}
                      setTabFilter={setFilters}
                      pageOptions={[30, 100, 250]}
                      matching={true}
                    />
                  </RenderIf>
                </>
              </RenderIf>
            </CardBody>
          </Card>
        </Container>
      </div>
      <RenderIf isTrue={props.Listings.showCustomFilters}>
        <CustomFilter
          activePlatform={storeFront.marketplace}
          filters={filters}
          setFilters={setFilters}
          vaIds={props.Listings.listings?.va_ids}
          is5050={props.Listings?.listings?.has_shared_listings}
          tenant={tenantObj?.data || {}}
          oosReasons={props.Listings?.listings?.oos_reasons || {}}
          marketplace={storeFront.marketplace}
        />
      </RenderIf>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { Listings, Supplier, User, Layout } = state;
  return { Listings, Supplier, User, Layout };
};

export default connect(mapStatetoProps, {
  fetchListings,
  fetchUsers,
  fetchMatchingPlatforms,
  fetchShippingTemplate,
  assignShippingTemplate,
  setSupplierModal,
  setPreloader,
  fetchSuppliers,
  setActionSupplier,
  resetFetchError,
  setSelectedListings,
  setListingsBulkUpdateModal,
  setSelectedLiveListing,
  setListingSuppliersCollapse,
  updateBrandRestrictionBulk,
  setShippingTemplateUpdateModal,
  bulkDeleteListings,
  relistRetiredListings,
  updateAOStatus,
  showCustomFiltersAction,
  downloadListings,
  setMaPriceModal,
  setCustomFiltersAction,
  setTwoStepUpdateModal,
  setTwoStepModal,
  fetchPlatforms,
  resetCustomFiltersAction,
})(Listings);
