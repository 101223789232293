import React, { useState } from "react";
import { Row, Button, CardBody, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { get } from "lodash";

import { setHideRow, setupManualMatches, addListingProduct } from "../../../store/actions";
import Modal from "../../../components/Common/Modal";
import ConfirmationModal from "./ConfirmationModal";

const ListingModal = (props) => {
  const { isOpen, toggleModal, match, currentState, setState, platform, costType, marketPlaceAccount, getUserInfo } =
    props;
  const dispatch = useDispatch();

  const [openConfirmationSimple, setOpenConfirmationSimple] = useState(null);
  const [openConfirmationShipping, setOpenConfirmationShipping] = useState(null);
  const [enableBrandRestriction, setEnableBrandRestriction] = useState(false);

  const openListingModal = (comfirmationState, setConfirmationState) => {
    toggleModal(match?._id, currentState, setState);
    toggleModal(match?._id, comfirmationState, setConfirmationState);
  };

  const handleListing = (data, isDropshippingEnabled, isBrandRestriction) => {
    const { brand, upc, supplierShippingFee, packageQuantity, supplierUrl, supplierPrice, matchableId, matchableType } =
      data;
    dispatch(
      setupManualMatches({
        consumer_id: marketPlaceAccount.access_id,
        platform,
        marketplace_id: get(data, `${platform}Identifier`),
        supplier_url: supplierUrl,
        supplier_price: supplierPrice,
        list_price: get(data, `${costType.value}`),
        supplier_shipping: supplierShippingFee,
        quantity_multiplier: packageQuantity,
        va_id: getUserInfo().id,
        upc,
        brand,
        listing_source: "profit_analyzer_listings",
        is_dropshipping_enabled: isDropshippingEnabled ? "true" : "false",
        skip_brand_restriction: isBrandRestriction ? "true" : "false",
      }),
    );
    dispatch(
      addListingProduct({
        email: getUserInfo()?.email,
        matchableId,
        matchableType,
        marketPlace: platform,
        listingType: isDropshippingEnabled ? "dropshipping" : "simple",
        skipBrandRestriction: isBrandRestriction ? "true" : "false",
      }),
    );
    const dataId = `${data?.matchableId}-${data?.matchableType}-${data?.costType}-${data?.feeType}`;
    dispatch(setHideRow({ _id: dataId }));
  };

  return (
    <>
      <Modal
        size="md"
        isOpen={isOpen}
        toggle={() => toggleModal(match?._id, currentState, setState)}
        style={{ width: "100%", maxWidth: "534px" }}
      >
        <div className="modal-header pt-3 pb-2">
          <h4 className="modal-title text-secondary" id="myLargeModalLabel">
            Product Listing
          </h4>
          <button
            onClick={() => toggleModal(match?._id, currentState, setState)}
            type="button"
            className="close text-white"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body p-2">
          <div className="px-2">
            <CardBody className="p-2">
              <h5>List As</h5>
              <Row>
                <Col className="px-2">
                  <Button
                    color="link"
                    className="text-white my-2 p-0"
                    style={{ textDecoration: "none" }}
                    onClick={() => openListingModal(openConfirmationShipping, setOpenConfirmationShipping)}
                  >
                    <div
                      className="border rounded text-center d-flex flex-column justify-content-center align-items-center"
                      style={{ width: "231px", height: "126px" }}
                    >
                      <i className="bx bx-cube bx-lg text-primary cursor-pointer"></i>
                      <p className="mb-0">Product Shipping Item</p>
                    </div>
                  </Button>
                </Col>
                <Col className="px-2">
                  <Button
                    color="link"
                    className="text-white my-2 p-0"
                    style={{ textDecoration: "none" }}
                    onClick={() => openListingModal(openConfirmationSimple, setOpenConfirmationSimple)}
                  >
                    <div
                      className="border rounded text-center d-flex flex-column justify-content-center align-items-center"
                      style={{ width: "231px", height: "126px" }}
                    >
                      <i className="bx bx-list-ul bx-lg text-primary cursor-pointer"></i>
                      <p className="mb-0">Simple Listing</p>
                    </div>
                  </Button>
                </Col>
              </Row>
            </CardBody>
            <div className="d-flex align-items-center custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input p-2 ml-3"
                id={`condition-${match?._id}`}
                checked={enableBrandRestriction}
                style={{ position: "static" }}
                onChange={() => {
                  setEnableBrandRestriction(!enableBrandRestriction);
                }}
              />

              <label
                htmlFor={`condition-${match?._id}`}
                className={`custom-control-label d-inline-flex mb-0 ${!enableBrandRestriction ? "text-muted" : ""}`}
              >
                Skip Brand Restriction
              </label>
            </div>
            <Row className="align-items-center d-flex justify-content-end px-2 py-3">
              <Button
                color="primary "
                outline
                className="waves-effect waves-light mr-3"
                onClick={() => toggleModal(match?._id, currentState, setState)}
              >
                Cancel
              </Button>
            </Row>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={openConfirmationSimple === match._id}
        toggleModal={toggleModal}
        currentState={openConfirmationSimple}
        setState={setOpenConfirmationSimple}
        match={match}
        dispatchAction={(data) => handleListing(data, false, enableBrandRestriction)}
        message="This item will be listed."
        buttonMsg="List Match"
      />
      <ConfirmationModal
        isOpen={openConfirmationShipping === match._id}
        toggleModal={toggleModal}
        currentState={openConfirmationShipping}
        setState={setOpenConfirmationShipping}
        match={match}
        dispatchAction={(data) => handleListing(data, true, enableBrandRestriction)}
        message="This match will be listed as a drop shipping item."
        buttonMsg="List Match"
      />
    </>
  );
};

export default ListingModal;
