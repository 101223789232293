import React from "react";
import { getValidStores, RenderIf } from "../../utils/common";
import { useSelector } from "react-redux";
import { components } from "react-select";
import SimpleSelect from "./SimpleSelect";
import getBrandImageSrc from "../../utils/brandImages";
import { MARKET_PLACES } from "../../constants";
import $ from "lodash";
const { Option } = components;

const StoreOption = (props) => (
  <RenderIf isTrue={props.data.label}>
    <Option {...props}>
      <RenderIf isTrue={getBrandImageSrc(props.data.marketplace)}>
        <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
      </RenderIf>
      {props.data.label}
    </Option>
  </RenderIf>
);

const SingleValue = (props) => {
  const marketplace = props.data?.marketplace;
  return (
    <components.SingleValue {...props}>
      <img src={getBrandImageSrc(marketplace)} alt={`${marketplace}-logo`} />
      {props?.data?.label}
    </components.SingleValue>
  );
};

const StoreSelector = ({
  onChange,
  value: selected,
  forRepricer = false,
  optionData,
  formStyle = {},
  isValid,
  setFilters,
  filters,
  marketplaces,
  width = 200,
  selectLabel,
  placeholder,
  setWidth = true,
  includeAllAccountsOption = true,
}) => {
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const stores = getValidStores(accounts, { forRepricer, isValid, marketplaces });
  if (!stores?.length) return null;

  return (
    <SimpleSelect
      name="marketplace_id"
      label={selectLabel}
      placeholder={placeholder}
      value={selected}
      onChange={(e) => {
        onChange(e);
        if (filters) {
          const updatedFilters = $.omit(filters, [
            "publish_status",
            "status",
            "strategy_id",
            "tag_id",
            "no_of_competitors",
            "fulfillment_type",
          ]);
          setFilters({ ...updatedFilters, page: 1, page_number: 1 });

          localStorage.setItem("listingFilters", JSON.stringify(updatedFilters));
          localStorage.setItem("listingsSelectedMarketplace", JSON.stringify(e));
        }
      }}
      options={stores.reduce(
        (init, acc, i) => {
          init.push(
            optionData
              ? optionData(acc)
              : {
                  label: acc.name || "",
                  value: acc.id ? acc.id : acc.value,
                  marketplace: acc.marketplace,
                  identifier: acc.marketplace === MARKET_PLACES.walmart ? acc.unique_identifier : acc.access_id,
                  access_id: acc.access_id,
                  valid: acc.valid,
                  key: i,
                },
          );
          return init;
        },
        includeAllAccountsOption ? [selected.value && !forRepricer ? { value: "", label: "All Accounts" } : []] : [],
      )}
      formStyle={setWidth ? { width: `${width}px`, ...formStyle } : formStyle}
      classNamePrefix="select2-selection"
      components={{ Option: StoreOption, SingleValue }}
    />
  );
};

export default StoreSelector;
