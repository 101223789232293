import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Form, CardBody, Container, Alert, CardTitle } from "reactstrap";
import Dropzone from "react-dropzone";
import * as $ from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import StoreSelector from "../../../components/Common/StoreSelector";
import { MARKET_PLACES } from "../../../constants/index";
import { RenderIf, isProfitCalculationAllowed, getUserInfo, removeURLQueryParams, getValidStores } from "../../../utils/common";
// actions
import * as actions from "../../../store/actions";
import { getAccount } from "../common";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import ReactTable from "../../../components/Common/Table";
import moment from "moment";
import CustomPagination from "../../../components/Common/CustomPagination";
import queryString from "query-string";
import Preloader from "../../../components/Common/Preloader";

const BulkUpdate = () => {
  const maxFileSize = 1024 * 1024 * 30;
  const isFirstRender = useRef(true);
  const [selectedFile, setselectedFile] = useState([]);
  const [fileSizeError, setFileSizeError] = useState();
  const [marketplaceAccount, setMarketplaceAccount] = useState("");
  const [marketplaceHistoryAccount, setMarketplaceHistoryAccount] = useState({ value: "", label: "" });
  const [historyFileType, setHistoryFileType] = useState({ value: "", label: "All Files" });
  const [filters, setFilters] = useState({ page: 1, per_page: 10 });
  const queryParams = queryString.parse(document.location.search);
  const [fileUploadError, setFileUploadError] = useState(null);
  const { error, success, loading, filesHistory, filesFeedsResults } = useSelector((state) => state.Repricer);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const stores = getValidStores(accounts, { forRepricer: true, isValid: true });
  const currentUser = getUserInfo()?.full_name || getUserInfo()?.username;
  const dispatch = useDispatch();

  const fileHistoryTable = [
    {
      title: "File Name",
      Content: ({ data }) => <div className="d-flex align-items-center">{data?.file_name || "N/A"}</div>,
    },
    {
      title: "Source",
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          {data?.submitted_by ? `Uploaded by ${$.startCase(data?.submitted_by)}` : "N/A"}
        </div>
      ),
    },
    {
      title: "Time",
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          {moment(data?.created_at).format("LLL") || "N/A"}
        </div>
      ),
    },
    {
      title: "Status",
      Content: ({ data }) => (
        <>
          <div className="d-flex align-items-center mb-1">
            Total:&nbsp;
            <span className="inventory-color">{(data?.processed_products || 0) + (data?.errored_products || 0)}</span>
          </div>
          <div className="d-flex align-items-center mb-1">
            <span className="d-flex gap-1">
              <span className="d-flex align-items-center gap-1 text-success">
                <i className="bx bx-xs bx-check-circle" />
                <span>Processed:&nbsp;{data?.processed_products || 0}</span>
              </span>
            </span>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span className="d-flex gap-1">
              <span className="d-flex align-items-center gap-1 text-danger">
                <i className="bx bx-xs bx-info-circle" />
                <span>Errored:&nbsp;{data?.errored_products || 0}</span>
              </span>
            </span>
          </div>
          <RenderIf
            isTrue={data?.aws_file_url}
            fallback={
              <span className="d-flex align-items-center mb-1 gap-1 cursor-not-allowed text-secondary">
                <i className="bx bx-xs bx-download cursor-pointer" />
                Download
              </span>
            }
          >
            <a className="d-flex align-items-center mb-1 gap-1 text-primary" href={data?.aws_file_url} rel="noreferrer">
              <i className="bx bx-xs bx-download cursor-pointer" />
              Download
            </a>
          </RenderIf>
        </>
      ),
    },
  ];

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  function handleListingSubmit () {
    if (!marketplaceAccount) return setFileUploadError("Account must be selected.");
    if (!selectedFile.length) return setFileUploadError("Please select a file.");
    dispatch(
      actions.uploadCSVListings(
        $.merge(
          { platform: marketplaceAccount?.marketplace, uploaded_by: currentUser, file: selectedFile[0] },
          getAccount(marketplaceAccount),
        ),
      ),
    );
    setFileUploadError(null);
    setFilters({ page: 1, per_page: 10 });
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const optionData = (acc) => ({
    label: acc.name || "",
    value: acc.id ? acc.id : acc.value,
    marketplace: acc.marketplace,
    identifier: acc.marketplace === MARKET_PLACES.walmart ? acc.unique_identifier : acc.access_id,
    key: 0,
  });

  useEffect(() => {
    dispatch(actions.setPreloader(loading));
  }, [loading]);

  useEffect(() => queryParams && removeURLQueryParams(), []);

  useEffect(() => {
    setTimeout(() => {
      fileSizeError && setFileSizeError();
      fileUploadError && setFileUploadError(null);
    }, 3000);
  }, [fileSizeError, fileUploadError]);

  useEffect(() => {
    setselectedFile([]);
  }, [success]);

  useEffect(() => {
    if (!$.isEmpty(accounts?.data)) setMarketplaceHistoryAccount(optionData($.first(accounts.data)));
  }, [accounts?.data]);

  useEffect(() => {
    marketplaceHistoryAccount.value &&
      dispatch(
        actions.fetchUploadFileHistory({
          feed_type: historyFileType.value,
          marketplace_account_id: marketplaceHistoryAccount?.identifier,
          platform: marketplaceHistoryAccount?.marketplace,
          ...filters,
          limit: filters.per_page,
          offset: (filters?.page - 1) * filters?.per_page,
        }),
      );
  }, [historyFileType, marketplaceHistoryAccount, filters]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    filters?.page && window.scrollTo({ top: 400, behavior: "smooth" });
  }, [filters?.page]);

  return (
    <>
      <RenderIf isTrue={loading}>
        <Preloader />
      </RenderIf>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Import" />

          <RenderIf isTrue={success}>
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {success || ""}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={error}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error || ""}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={fileSizeError}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {fileSizeError}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={(!marketplaceAccount || fileUploadError) && fileUploadError}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {fileUploadError}
              </Alert>
            </div>
          </RenderIf>
          <RenderIf isTrue={!isProfitCalculationAllowed()}>
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              You don't have access to update COGS of the products. Please subscribe to{" "}
              <b>Basic Plan or Repricer plan</b> to use this feature.
            </Alert>
          </RenderIf>

          <Row>
            <Col className="col-12">
              <RenderIf isTrue={isProfitCalculationAllowed()}>
                <Card>
                  <div style={{ marginLeft: 20, marginTop: 20 }} className="d-flex justify-content-between">
                    <CardTitle className="inventory-color">Upload File Area</CardTitle>
                    <div className="d-flex">
                      <div className="d-flex">
                        <a
                          className="mr-3 btn btn-primary waves-effect waves-light"
                          href={"/template_file_update_supplier_cost.csv"}
                          download
                        >
                          <div className="d-flex align-items-center">
                            <i className="bx bx-sm bx-download text-white mr-3 cursor-pointer" />
                            Download Sample File
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <CardBody>
                    <div className="m-2">
                      <div className="d-flex mb-2">
                        Account<span className="text-danger">*</span>
                      </div>
                      <div className="d-flex mb-4">
                        <StoreSelector
                          isValid={true}
                          formStyle={{ marginBottom: "0px", width: "300px" }}
                          forRepricer={true}
                          optionData={optionData}
                          value={marketplaceAccount}
                          onChange={setMarketplaceAccount}
                        />
                      </div>
                    </div>
                    <div className="m-2">File:</div>
                    <Form>
                      <Dropzone
                        disabled={!marketplaceAccount || !isProfitCalculationAllowed()}
                        onDropRejected={(d) => setFileSizeError("File size must be less than 15MB.")}
                        maxFiles="1"
                        maxSize={maxFileSize}
                        onDrop={(acceptedFile, fileRejections) => {
                          if (fileRejections.length) {
                            const [file] = fileRejections;
                            if (file.type !== ".csv")
                              return setFileSizeError("Invalid File type. It must be a CSV file");
                            if (file.size > maxFileSize)
                              return setFileSizeError(`Max allowed file size is ${formatBytes(maxFileSize)}`);
                          }
                          handleAcceptedFile(acceptedFile);
                        }}
                        accept=".csv"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h4>Drag or Click to Upload CSV File</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedFile.map((f, i) => (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col>
                                  {f.name}
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <i
                                  title="Remove File"
                                  className="bx bx-sm mdi mdi-close pr-4 text-danger cursor-pointer"
                                  onClick={() => setselectedFile([])}
                                />
                              </Row>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </Form>
                    <RenderIf
                      isTrue={[marketplaceAccount, !fileSizeError, !fileUploadError, !$.isEmpty(selectedFile)].every(
                        (x) => x,
                      )}
                    >
                      <div className="text-left mt-4">
                        <button
                          disabled={!isProfitCalculationAllowed()}
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={handleListingSubmit}
                        >
                          Upload
                        </button>
                      </div>
                    </RenderIf>
                  </CardBody>
                </Card>
              </RenderIf>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardTitle style={{ marginLeft: 20, marginTop: 20, marginRight: 20 }}>
                  <Breadcrumbs
                    isCapitalize={true}
                    title="Uploaded File History"
                    children={
                      <div className="d-flex float-sm-right gap-3">
                        <RenderIf isTrue={stores?.length > 1}>
                          <StoreSelector
                            isValid={true}
                            formStyle={{ marginBottom: "0px", width: "200px", fontWeight: "100" }}
                            forRepricer={true}
                            optionData={optionData}
                            value={marketplaceHistoryAccount}
                            onChange={(e) => {
                              setMarketplaceHistoryAccount(e);
                              setFilters({ page: 1, per_page: 10 });
                              removeURLQueryParams();
                            }}
                          />
                        </RenderIf>
                        <SimpleSelect
                          name="file_type"
                          formStyle={{ width: "200px", fontWeight: "100" }}
                          classNamePrefix="select2-selection"
                          options={[
                            { value: "", label: "All Files" },
                            { value: "COST", label: "Cost" },
                            { value: "PRICE", label: "Price" },
                            { value: "LISTING", label: "Listing" },
                          ]}
                          value={historyFileType}
                          onChange={(e) => {
                            setHistoryFileType(e);
                            setFilters({ page: 1, per_page: 10 });
                            removeURLQueryParams();
                          }}
                        />
                      </div>
                    }
                  />
                </CardTitle>
                <CardBody>
                  <ReactTable
                    className="table-select"
                    tableHeader={
                      <>
                        {fileHistoryTable.map(({ title }, index) => (
                          <th key={`table-header-${index}`} className={[0, 3].includes(index) ? null : "text-center"}>
                            {title}
                          </th>
                        ))}
                      </>
                    }
                    style={{ width: "100%", overflow: "auto" }}
                    tableBody={
                      <RenderIf
                        isTrue={!$.isEmpty(filesFeedsResults)}
                        fallback={
                          <RenderIf isTrue={!loading}>
                            <tr>
                              <td colSpan="100%">
                                <h2 className="text-center">No Records Found</h2>
                              </td>
                            </tr>
                          </RenderIf>
                        }
                      >
                        {$.map(filesFeedsResults, (data, key) => (
                          <tr key={`table-row-${key}`}>
                            {fileHistoryTable.map(({ Content }, colKey) => (
                              <td key={`table-col-${colKey}`}>
                                <Content data={data} index={key} />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </RenderIf>
                    }
                  />
                  <RenderIf isTrue={!$.isEmpty(filesHistory?.results)}>
                    <CustomPagination
                      total={filesHistory?.count}
                      page={filters?.page || +queryParams?.page || 1}
                      perPage={filters?.per_page || +queryParams?.per_page || 10}
                      tabsFilter={filters}
                      setTabFilter={setFilters}
                      pageOptions={[10, 50, 100]}
                      repricer={true}
                      inAgencyComponent={true}
                    />
                  </RenderIf>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BulkUpdate;
