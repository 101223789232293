import {
  Button,
  ButtonDropdown,
  Card,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row,
} from "reactstrap";
import * as $ from "lodash";
import Select from "react-select";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MARKET_PLACES,
  REPRICER_LISTING_SEARCH_TYPES,
  REPRICER_LISTING_TYPES,
  REPRICER_LISTINGS_PAGES,
} from "../../../constants";
import * as actions from "../../../store/repricer/actions";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import StoreSelector from "../../../components/Common/StoreSelector";
import { getAccount, isVisible, setLocalStorageItems } from "../common";
import { actions as localRepricerActions } from "../LocalStore/RepricerListingsStore";
import { getValidStores, isAdmin, isJSON, isProfitCalculationAllowed, RenderIf } from "../../../utils/common";

const TableToolBar = ({ cols, state, setCols, tableHeaders, toggleDrawer, handleDispatch }) => {
  const isUserAdmin = isAdmin();
  const dispatch = useDispatch();
  const [selectColumnsDropDown, setSelectColumnsDropDown] = useState(false);
  const [strategydropdownIsOpen, setDropdownIsOpen] = useState(false);
  const selectedListingsCount = $.size($.keys(state?.checkBox));
  const isAnyListingSelected = state?.selectAll || selectedListingsCount;
  const queryParams = queryString.parse(document.location.search);
  const { loading, strategies } = useSelector((state) => state.Repricer);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const getLocalStorageItems = (key) => localStorage.getItem(key);
  const stores = getValidStores(accounts, { forRepricer: true });

  const onSubmit = (e) => {
    e.preventDefault();
    if (!state?.search.trim()) return;

    handleDispatch(
      localRepricerActions.setFilters({
        ...state?.filters,
        search: state?.search.trim(),
        page: 1,
        search_on: REPRICER_LISTING_SEARCH_TYPES[state?.searchType.label],
      }),
    );
  };

  const optionData = (acc) => ({
    label: acc.name || "",
    value: acc.id ? acc.id : acc.value,
    marketplace: acc.marketplace,
    identifier: acc.marketplace === MARKET_PLACES.walmart ? acc.unique_identifier : acc.access_id,
    valid: acc.valid,
    is_archive: acc.is_archive,
  });

  const getFieldsToOmit = () => {
    if (state?.listingsPage.value === REPRICER_LISTING_TYPES.live) {
      if (state?.storeFront.marketplace === MARKET_PLACES.amazon) {
        return ["Item Id", "UPC"];
      }
      return ["Asin", "UPC"];
    }

    if (state?.storeFront.marketplace === MARKET_PLACES.amazon) {
      return ["UPC", "Item Id"];
    }
    return ["Asin", "Item Id"];
  };

  useEffect(() => {
    const marketplace = isJSON(getLocalStorageItems("listingsSelectedMarketplace"));
    if (stores?.length) {
      const { account_id: accountId } = queryParams;
      const acc = (accountId && $.find(stores, ({ id }) => id === +accountId)) || $.first(stores);
      handleDispatch(localRepricerActions.setStoreFront(marketplace && !accountId ? marketplace : optionData(acc)));
    }
  }, [accounts?.data]);

  return (
    <Card className="mb-4">
      <CardHeader className="py-2">
        <Row className="d-flex justify-content-between align-items-center mr-1">
          <div className="d-flex">
            <form className="app-search d-lg-block" onSubmit={onSubmit}>
              <input type="submit" hidden />
              <div className="d-flex inputs-group">
                <Select
                  name="select_type"
                  value={state?.searchType}
                  className="select2-container mb-0 form-group"
                  onChange={(e) => {
                    const { account_id: accountId } = queryParams;
                    const searchType = { label: e.label, value: e.value };
                    handleDispatch(localRepricerActions.setSearchType(searchType));
                    if (!accountId) {
                      setLocalStorageItems("listingsSearchType", JSON.stringify(searchType));
                    }
                    state?.search &&
                      handleDispatch(
                        localRepricerActions.setFilters({
                          ...state?.filters,
                          search: state?.search.trim(),
                          page: 1,
                          search_on: REPRICER_LISTING_SEARCH_TYPES[e.label],
                        }),
                      );
                  }}
                  options={$.map(
                    $.entries($.omit(REPRICER_LISTING_SEARCH_TYPES, getFieldsToOmit())),
                    ([label, value]) => ({
                      label,
                      value,
                    }),
                  )}
                  classNamePrefix="select2-selection"
                />
                <div className="vertical-line2" />
                <div className="position-relative">
                  <i
                    onClick={onSubmit}
                    className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"
                  />
                  <input
                    id="search_field"
                    type="text"
                    className="form-control search-input"
                    placeholder="Search..."
                    value={state?.search}
                    onChange={(e) => {
                      if (!e.target.value) {
                        handleDispatch(localRepricerActions.setFilters({ ...state?.filters, search: "", page: 1 }));
                        handleDispatch(localRepricerActions.setSearch(""));
                        localStorage.removeItem("listingsSearch");
                      } else {
                        const { account_id: accountId } = queryParams;
                        handleDispatch(localRepricerActions.setSearch(e.target.value));
                        if (!accountId) {
                          setLocalStorageItems("listingsSearch", e.target.value);
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="d-flex align-items-center gap-3">
            {state?.listingsPage.value === REPRICER_LISTING_TYPES.live && (
              <>
                <Dropdown className="d-none" isOpen={false} direction="down" toggle={() => {}}>
                  <DropdownToggle
                    className="toc-heading-cursor ml-3 mb-0"
                    data-toggle="dropdown"
                    aria-expanded={false}
                    tag="span"
                  >
                    <i className="bx bx-sm bx-download text-primary ml-4 cursor-pointer" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem key="_download_skus_" onClick={() => {}}>
                      Download SKUs
                    </DropdownItem>
                    <DropdownItem key="_download_missing_suppliers_" onClick={() => {}}>
                      Download SKUs Missing Costs
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <ButtonDropdown
                  isOpen={selectColumnsDropDown}
                  toggle={() => setSelectColumnsDropDown(!selectColumnsDropDown)}
                  className="mr-1"
                >
                  <DropdownToggle tag="i" className="bx bx-sm bx-columns text-primary cursor-pointer" />
                  <DropdownMenu className="overflow-auto" style={{ maxHeight: "300px" }}>
                    {tableHeaders
                      .filter((x) => isVisible(x, false))
                      .map((header, key) => (
                        <DropdownItem key={`_select_columns_+ ${key}`}>
                          <div
                            className="custom-control custom-checkbox"
                            onClick={(e) => {
                              e.stopPropagation();
                              const { account_id: accountId } = queryParams;
                              cols[header.title].show =
                                $.size($.values(cols).filter((value) => value.show)) > 1
                                  ? !cols[header.title]?.show
                                  : true;
                              setCols({ ...cols });
                              if (!accountId) {
                                setLocalStorageItems("repricerListingsColumns", JSON.stringify(cols));
                              }
                            }}
                          >
                            <input
                              checked={!!cols[header.title]?.show}
                              type="checkbox"
                              className="custom-control-input"
                              onChange={() => false}
                            />
                            <label className="custom-control-label">{header.title}</label>
                          </div>
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                </ButtonDropdown>
                <Button disabled={loading} onClick={toggleDrawer} className="transparent-button">
                  <i
                    className={`bx bx-sm bx-filter-alt text-primary ${
                      loading ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                  />
                </Button>
              </>
            )}
            <RenderIf isTrue={stores.length > 1}>
              <FormGroup className="mx-1 select2-container mt-3">
                <StoreSelector
                  value={state?.storeFront}
                  filters={state?.filters}
                  setFilters={(filters) => handleDispatch(localRepricerActions.setFilters(filters))}
                  onChange={(value) => {
                    handleDispatch(localRepricerActions.setStoreFront(value));
                  }}
                  forRepricer={true}
                  optionData={optionData}
                  formStyle={{ marginBottom: "0" }}
                  width={140}
                />
              </FormGroup>
            </RenderIf>
            <RenderIf isTrue={isUserAdmin}>
              <SimpleSelect
                name="listing_page"
                value={state?.listingsPage}
                onChange={(e) => {
                  handleDispatch(localRepricerActions.setListingsPage({ label: e.label, value: e.value }));
                  handleDispatch(
                    localRepricerActions.setFilters({
                      page: 1,
                      page_number: 1,
                      per_page: Number(queryParams.per_page) || 50,
                    }),
                  );
                  handleDispatch(localRepricerActions.setSearch(""));
                  handleDispatch(localRepricerActions.setSearchType({ label: "All", value: "" }));
                }}
                options={REPRICER_LISTINGS_PAGES}
                formStyle={{ width: "140px", marginTop: "17px" }}
                classNamePrefix="select2-selection"
              />
            </RenderIf>
            <RenderIf isTrue={isAnyListingSelected}>
              <ButtonDropdown
                isOpen={strategydropdownIsOpen}
                direction="down"
                toggle={() => setDropdownIsOpen(!strategydropdownIsOpen)}
              >
                <DropdownToggle color="success" className="rounded">
                  Assign Strategy
                  <i className="mdi mdi-chevron-down ml-1" />
                </DropdownToggle>
                <DropdownMenu className="overflow-auto" style={{ maxHeight: "300px" }} position="absolute">
                  {$.map(strategies, (strategy, ind) => (
                    <DropdownItem
                      key={`strategy-${ind}`}
                      onClick={() => {
                        dispatch(actions.setAssignStrategyModal(true));
                        handleDispatch(
                          localRepricerActions.setAssignStrategyModalProps({
                            productKey: "strategy",
                            strategy: strategy?.name,
                            strategyId: strategy?.id,
                            selectAll: state?.selectAll,
                            selectedlistings: $.keys(state?.checkBox),
                            params: $.merge(
                              $.omit(state?.filters, ["sort_by", "sort_dir", "ordering", "per_page", "page_number"]),
                              {
                                platform: state?.storeFront?.marketplace,
                                marketplace_account_id: state?.storeFront?.identifier,
                              },
                              getAccount(state?.storeFront),
                            ),
                            setSelectAll: (selectAll) => handleDispatch(localRepricerActions.setSelectAll(selectAll)),
                            setCheckBox: (checkBox) => handleDispatch(localRepricerActions.setCheckBox(checkBox)),
                          }),
                        );
                      }}
                    >
                      {strategy?.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </RenderIf>
            <RenderIf isTrue={isProfitCalculationAllowed() && isUserAdmin}>
              <Button
                color="primary"
                outline
                className="waves-effect waves-light"
                onClick={() => handleDispatch(localRepricerActions.setIsAddBulkListingOpen(true))}
              >
                Add Listings in Bulk
              </Button>
              <Button color="primary" onClick={() => handleDispatch(localRepricerActions.setIsAddListingOpen(true))}>
                Add Listing
              </Button>
            </RenderIf>
          </div>
        </Row>
      </CardHeader>
    </Card>
  );
};

export default TableToolBar;
