import React from "react";
import ReactTable from "./Table";

const InformationTableInCard = ({ cardMessage, infoMessage, tableHeader, tableBody }) => {
  return (
    <div className="card-table px-4 pt-4 pb-2 rounded-lg mt-4">
      <div className="d-flex align-items-center justify-content-between headings-text flex-wrap px-4">
        <div className="d-flex align-items-center font-weight-bold font-size-16">{cardMessage}</div>
        <div className="d-flex align-items-center">
          <i className="bx bx-info-circle" style={{ fontSize: "20px", fontWeight: "bold", color: "#556ee6" }}></i>
          <span className="ml-2 font-italic font-size-13">{infoMessage}</span>
        </div>
      </div>
      <div className="px-4 pt-4 pb-1">
        <ReactTable
          className=""
          tableHeader={tableHeader}
          style={{ width: "100%", overflow: "auto" }}
          tableBody={tableBody}
          invSection={true}
        />
      </div>
    </div>
  );
};

export default InformationTableInCard;
