import * as $ from "lodash";
import { MARKET_PLACES } from "../../constants";
import { getRepricerAccountData } from "../../utils/common";

export const getAccount = (store) => {
  const account = getRepricerAccountData(store);
  return $.isEmpty(account) ? {} : { account };
};

export const isStoreWalmart = (marketplace) => marketplace === MARKET_PLACES.walmart;

export const setLocalStorageItems = (key, value) => localStorage.setItem(key, value);

export const isVisible = (x, isShow = true) => {
  if (x.isHide) return false;
  if (!x) return false;
  if (!x.show && isShow) return false;
  return true;
};
