import * as $ from "lodash";
import Dropzone from "react-dropzone";
import queryString from "query-string";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Form, CardBody, Container, Alert, CardTitle } from "reactstrap";
import * as actions from "../../../store/actions";
import ListingDescription from "./ListingDescription";
import { MARKET_PLACES } from "../../../constants/index";
import Preloader from "../../../components/Common/Preloader";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import StoreSelector from "../../../components/Common/StoreSelector";
import { RenderIf, removeURLQueryParams } from "../../../utils/common";

const AddListingInBulk = ({ setIsAddBulkListingOpen, selectedMarketplaceAccount }) => {
  const maxFileSize = 1024 * 1024 * 30;
  const queryParams = queryString.parse(document.location.search);
  const dispatch = useDispatch();
  const [selectedFile, setselectedFile] = useState([]);
  const [fileSizeError, setFileSizeError] = useState();
  const [fileUploadError, setFileUploadError] = useState(null);
  const [marketplaceAccount, setMarketplaceAccount] = useState(selectedMarketplaceAccount || "");
  const { error, successMsg, loading } = useSelector((state) => state.Repricer);

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  function handleListingSubmit () {
    if (!marketplaceAccount) return setFileUploadError("Account must be selected.");
    if (!selectedFile.length) return setFileUploadError("Please select a file.");
    const selectedPlatform = $.get(marketplaceAccount, "marketplace");
    const mpAccountId = $.get(marketplaceAccount, "identifier");
    dispatch(actions.bulkCreateListings(selectedPlatform, mpAccountId, selectedFile[0]));
    setFileUploadError(null);
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const optionData = (acc) => ({
    label: acc.name || "",
    value: acc.id ? acc.id : acc.value,
    marketplace: acc.marketplace,
    identifier: acc.marketplace === MARKET_PLACES.walmart ? acc.unique_identifier : acc.access_id,
    key: 0,
  });

  useEffect(() => queryParams && removeURLQueryParams(), []);

  useEffect(() => {
    setTimeout(() => {
      fileSizeError && setFileSizeError();
      fileUploadError && setFileUploadError(null);
    }, 3000);
  }, [fileSizeError, fileUploadError]);

  useEffect(() => {
    setselectedFile([]);
  }, [successMsg]);

  return (
    <>
      <RenderIf isTrue={loading}>
        <Preloader />
      </RenderIf>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb
            title={
              <span className="d-flex align-items-center font-weight-bold font-size-16">
                <i
                  className="text-primary bx bx-sm bx-left-arrow-alt cursor-pointer mr-2"
                  onClick={() => setIsAddBulkListingOpen(false)}
                />
                ADD LISTINGS IN BULK
              </span>
            }
          />

          <RenderIf isTrue={successMsg}>
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {successMsg || ""}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={error}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error || ""}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={fileSizeError}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {fileSizeError}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={(!marketplaceAccount || fileUploadError) && fileUploadError}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {fileUploadError}
              </Alert>
            </div>
          </RenderIf>

          <Row>
            <Col className="col-12">
                <Card>
                  <div style={{ marginLeft: 20, marginTop: 20 }} className="d-flex justify-content-between flex-wrap">
                    <CardTitle className="inventory-color">Upload File Area</CardTitle>
                    <div className="d-flex">
                      <div className="d-flex">
                        <a
                          className="mr-3 btn btn-primary waves-effect waves-light"
                          href={marketplaceAccount?.marketplace === MARKET_PLACES.amazon ? "template_file_for_bulk_amazon_listings.csv" : "template_file_for_bulk_walmart_listings.csv"}
                          download
                        >
                          <div className="d-flex align-items-center">
                            <i className="bx bx-sm bx-download text-white mr-3 cursor-pointer" />
                            Download Sample File
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <CardBody>
                    <div className="m-2">
                      <div className="d-flex mb-2">
                        Account<span className="text-danger">*</span>
                      </div>
                      <div className="d-flex mb-4">
                        <StoreSelector
                          isValid={true}
                          formStyle={{ marginBottom: "0px", width: "300px" }}
                          forRepricer={true}
                          optionData={optionData}
                          value={marketplaceAccount}
                          onChange={setMarketplaceAccount}
                          includeAllAccountsOption={false}
                        />
                      </div>
                    </div>
                    <div className="m-2">File:</div>
                    <Form>
                      <Dropzone
                        disabled={!marketplaceAccount}
                        onDropRejected={(d) => setFileSizeError("File size must be less than 15MB.")}
                        maxFiles="1"
                        maxSize={maxFileSize}
                        onDrop={(acceptedFile, fileRejections) => {
                          if (fileRejections.length) {
                            const [file] = fileRejections;
                            if (file.type !== ".csv")
                              return setFileSizeError("Invalid File type. It must be a CSV file");
                            if (file.size > maxFileSize)
                              return setFileSizeError(`Max allowed file size is ${formatBytes(maxFileSize)}`);
                          }
                          handleAcceptedFile(acceptedFile);
                        }}
                        accept=".csv"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h4>Drag or Click to Upload CSV File</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedFile.map((f, i) => (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col>
                                  {f.name}
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <i
                                  title="Remove File"
                                  className="bx bx-sm mdi mdi-close pr-4 text-danger cursor-pointer"
                                  onClick={() => setselectedFile([])}
                                />
                              </Row>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </Form>
                    <RenderIf
                      isTrue={[marketplaceAccount, !fileSizeError, !fileUploadError, !$.isEmpty(selectedFile)].every(
                        (x) => x,
                      )}
                    >
                      <div className="text-left mt-4">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={handleListingSubmit}
                        >
                          Upload
                        </button>
                      </div>
                    </RenderIf>
                  </CardBody>
                </Card>
            </Col>
          </Row>
          <div className="d-flex justify-content-start">
            <ListingDescription marketplace={marketplaceAccount.marketplace} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default AddListingInBulk;
